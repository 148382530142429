import userEvent from "@testing-library/user-event";
import React from "react";
import Commons from "../utils/commons";

class JobItem extends React.Component{
    constructor(props){
        super(props);
        this.storage = window.localStorage;
        this.storedData = JSON.parse(this.storage.getItem("data"));
        this.loginState = JSON.parse(window.sessionStorage.getItem("loginState"));
        this.handleClick = this.handleClick.bind(this);
        this.applications = this.storedData.applications;
        this.jobLabels = Commons.getJobListLabels().filter(f=>f.key == "date_created"||f.key == "title" || f.key == "location" || f.key == "experience"||f.key=="deadline");
        this.state={job:props.job}
       
        this.width = "col-lg-2 col-md-2";
        if(this.jobLabels.length > 6) this.width = "col-lg-1 col-md-1";
        if(this.jobLabels.length == 4) this.width = "col-lg-3 col-md-3";
        if(this.jobLabels.length == 3) this.width = "col-lg-4 col-md-4";
        this.align = "text-left";
    }

    handleClick=()=>{
        this.props.onClick(this.state.job);
    }
    componentDidMount(){
        if(this.loginState.level != Commons.LEVEL_ADMIN) {
            this.jobLabels.push({key:"applied",label:"Applied",type:"int"});
            if(this.props.job){
                this.applications = this.storedData.applications.filter(f=>f.job_id == this.props.job.id);
                
                let job = this.state.job;
                if(job){
                    (this.applications.length > 0)? job.applied = "Yes":job.applied = "No";
                this.setState({job:job});
                }
            }
        }
        else{
            this.jobLabels.push({key:"status",label:"Status",type:"int"})
        }
    }
    render(){
        return(
            this.state.job ? (<div onClick={this.handleClick} className={"mx-auto row col-md-12 col-lg-12 d-flex flex-row justify-content-start align-items-center text-left list-item "+((this.props.position%2==0) ? "list-item-shade":"bg-light")}>
            {
                this.jobLabels.map((d,i)=>{

                    let now = new Date();
                    let val = this.state.job[d.key];
                    if(d.key == "experience") val +=" year(s)";
                    if(d.key == "location") {
                        let x = val.substr(0,1).toUpperCase();
                        val = x + val.substr(1);
                        val = val.replaceAll("_"," ");
                    }
                    if(d.key == "deadline" || d.key == "date_created") {
                        let x = new Date(parseInt(val));
                        if(x.getDay() == now.getDay() && x.getMonth() == now.getMonth()) val = "Today";
                        else if((x.getMonth() == now.getMonth()) && (x.getDay()+1 == now.getDay())) val = "Yesterday";
                        else if((x.getMonth() == now.getMonth()) && (x.getDay()-1 == now.getDay())) val = "Tomorrow";
                        else val = Commons.getDays()[x.getDay()]+ " "+Commons.generateMonths()[x.getMonth()]+" "+x.getDate()+", "+x.getFullYear();
                        
                    }
                    if(d.key == "status"){
                        val = (val > now.getMilliseconds()) ? "Open":"Expired";
                    }
                    return(<div key={i} className={this.width+ " "+this.align }>{val}</div>)
                
                })  
            }</div>):(
                    <div className="text-center col-lg-12 col-md-12">No information</div>
                )
                
            
        )
    }
}
export default JobItem;