import React, { useState } from "react";

const SkillPill = (props)=>{

    const [select,setSelect] = useState(props.selected);

    const selectSkill=()=>{
       props.onSelect(props.id);
       
    }
    const removeSkill=(e)=>{
        let id = "target_"+props.id;
    //     document.getElementById(id).classList.add("hidden");
        props.onRemove(props.id);
    }
    return(
        <div className="pill_case" id={"target_"+props.id}>
            <span className={select ? "pill_selected":"pill"} onClick={()=>{props.onSelect(props.id)}}>
                {props.name}
            </span>
            {props.removable ? <span className="hidden material-icons btn-close" onClick={(e)=>removeSkill(e)}>close</span>:null}
        </div>
    )
}
export default SkillPill;