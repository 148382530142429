import React from "react";

const Header=(props)=>{
    const loginState = JSON.parse(window.sessionStorage.getItem("loginState"));
    const handleSignout=(e)=>{
        e.preventDefault();
        window.sessionStorage.clear();
        window.localStorage.clear();
        window.location.href = "/";
    }
    const handleSearch=(e)=>{
        props.onSearch(e.target.value.toLowerCase())
    }
    return(
    <header id="header" className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
    <div className="d-flex d-flex-row justify-content-between w-100">
    <a className="navbar-brand col-md-3 col-lg-2  col-sm-12 me-0 px-3">Pannecia HR v.1.0</a>
    <button type="button" className="navbar-toggler d-md-none collapsed" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Menu">
        <span className="navbar-toggler-icon"></span>
    </button>
    </div>
    <input className="form-control form-control-dark w-80" type="text" placeholder="search" aria-label="Search" onChange={handleSearch}></input>
    <div className="navbar-nav">
        <div className="nav-item text-nowrap">
            <a className="nav-link px-3" href="/profile">{loginState.fname}</a>
        </div>
    </div>
    <div className="navbar-nav">
        <div className="nav-item text-nowrap">
            <a className="nav-link px-3" href="/" onClick={handleSignout}>Sign out</a>
        </div>
    </div>
</header>)
}
export default Header;