import React,{useEffect,useState} from "react";
import {Bar, Line,Pie} from "react-chartjs-2"
import Commons from "../utils/commons";
import Header from "./header";
import SideBar from "./side_bar";
import Spinner from "./spinner";
import LoginForm from "./login_form";

class Dashboard extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            isAuth:false,prevPath:"",
            jobsCount:0,usersCount:0,applicationCount:0,pieData:[],obsData:[],barData:[],myJobs:[]
        }
        this.storage = window.localStorage;
        this.session = window.sessionStorage;
        this.loginState = (this.session.getItem("loginState") != null) ? JSON.parse(this.session.getItem("loginState")):null;
        this.storedData = JSON.parse(this.storage.getItem("data"));       

        this.handleAuth = this.handleAuth.bind(this);
        this.handleSignout = this.handleSignout.bind(this);
        this.prepareData = this.prepareData.bind(this);
        this.loadData = this.loadData.bind(this);

        if(window.performance){
            if(performance.navigation.type === 1){
                this.loadData().then(result=>{
                    console.log("page refreshed",result)
                    if(this.loginState.level === Commons.LEVEL_ADMIN) this.storedData.users = result.users;
                    this.storedData.applications = result.applications;
                    this.storedData.jobs = result.jobs;
                    this.storedData.settings = result.settings;
                    this.storedData.job_fields = result.job_fields;
                    this.storedData.application_fields = result.application_fields;
                    this.storedData.user_fields = result.user_fields;
                    this.storedData.skills = result.skills;
                    this.storage.setItem("data",JSON.stringify(this.storedData));
                    this.storedData = JSON.parse(this.storage.getItem("data"));
                    this.prepareData(this.storedData)
                })
                .catch(e=>{
                    console.error("error: ",e);
                })
            }
        }
    }
    loadData=()=>{
        console.log("check")
        return new Promise((resolve,reject)=>{
           
            if(this.loginState){
                let user_url = Commons.getURL("data")+"/index.php";
                var options={
                    method:"POST",
                    body:JSON.stringify({auth:this.loginState}),
                    headers:{'Content-type':'application/json'}
                }
                fetch(user_url,options)
                .then(res=>res.json())
                .then(result=>{
                    resolve(result);
                })
                .catch(e=>{
                    console.log(e);
                    reject("Oops! Something went wrong");
                })
                
            }
            
        })
    }
    handleSignout=(e)=>{
        e.preventDefault();
        window.sessionStorage.clear();
        window.localStorage.clear();
        window.location.href = "/";
    }
    prepareData = (data)=>{
        var jobsData = Commons.groupBy(data.jobs.map(g=>{
            let k = g;
            let m = new Date(parseInt(g.date_created));
            k.month = Commons.generateMonths()[m.getMonth()];
            return k;
        }),"month");
        var myJobs = data.applications.map(a=>a.job_id).map(jid=>{
            return data.jobs.find(jb=>jb.id == jid);
        });
        
       var barData = jobsData.map(j=>j.val.length);
       let now = Date.now();
       var activeJobs = data.jobs.filter(j=>j.deadline > now);
       var activeApps = data.applications.map(a=>{
           let app = a;
           app.job = data.jobs.find(j=>j.id == app.job_id);
           return app;
       }).filter(app=>app.job.deadline > now);
       var shortListed = data.applications.filter(a=>a.status == 1).length;
       let appCount = this.loginState.level == Commons.LEVEL_ADMIN ? activeApps.length:data.applications.length;
       let myJobsCount = this.loginState.level == Commons.LEVEL_ADMIN ? activeJobs.length:myJobs.length;
       this.setState({
        myJobsCount:myJobsCount,barData:barData,myJobs:myJobs,usersCount:data.users.length,applicationCount:appCount,jobsCount:myJobsCount.length,pieData:[data.jobs.length,myJobs.length,shortListed]
    },()=>{})
    }
    
    handleAuth=(u)=>{
        this.session.setItem("loginState",JSON.stringify(u));
        this.loginState = JSON.parse(this.session.getItem("loginState"));
        this.storedData = JSON.parse(this.storage.getItem("data"));
        this.prepareData(this.storedData);
    }
   componentDidMount(){
        if(this.loginState){
           
            this.setState({isAuth:true},()=>{
                this.loadData().then(result=>{
                    if(this.loginState.level == Commons.LEVEL_ADMIN) {
                        this.storedData.users = result.users;
                    }
                    this.storedData.applications = result.applications;
                    this.storedData.jobs = result.jobs;
                    this.storedData.settings = result.settings;
                    this.storedData.job_fields = result.job_fields;
                    this.storedData.application_fields = result.application_fields;
                    this.storedData.user_fields = result.user_fields;
                    this.storedData.skills = result.skills;
                    this.storage.setItem("data",JSON.stringify(this.storedData));
                    this.storedData = JSON.parse(this.storage.getItem("data"));
                    this.prepareData(this.storedData);
                })
               
            })
        }

    }
    
    componentWillReceiveProps(nextProps) {
        if (nextProps.location !== this.props.location) {
            this.setState({ prevPath: this.props.location })
        }
    }
    render(){
        if(this.loginState){
            return(
                <div>
               <Header/>
                <div className="container-fluid">
                    <div className="row">
                      <SideBar selected="dashboard" />
                       
                        <main className="mx-auto col-md-10 col-lg-10 d-flex flex-wrap">
                        {this.state.isLoading ? <Spinner isShowing={this.state.isLoading} size="spinner-large"/>:<>
                            <div className="col-md-12 col-lg-12 my-2 mx-auto d-flex flex-wrap justify-content-center row ">
                            {parseInt(this.loginState.level) == 1?
                                <div id="sum" className="shadow col-sm-5 offset-sm-1 col-md-3 mx-auto col-lg-3 mx-1 my-2 border summary-card">
                                        <p>Registered Candidates</p>
                                        <p className="text-success text-center fw-bold text-medium">{Commons.thousandSeparator(this.state.usersCount)}</p>
            
                                </div>: null}
                                {/* <div id="sum" className="shadow col-sm-5 offset-sm-1 col-md-3 mx-auto col-lg-3 mx-1 my-2 border summary-card">
                                        <p>Open Jobs</p>
                                        <p className="text-success text-center fw-bold text-medium">{Commons.thousandSeparator(this.state.jobsCount)}</p>
            
                                </div> */}
                            
                                <div id="sum" className="shadow col-sm-5 offset-sm-1 col-md-3 mx-auto col-lg-3 mx-1 my-2 border summary-card">
                                    <p>{parseInt(this.loginState.level) == 0?"My Applications":"Received Applications"}</p>
                                    <p className="text-success text-center fw-bold text-medium">{Commons.thousandSeparator(this.state.applicationCount)}</p>
        
                                </div>
                                <div id="sum" className="shadow col-sm-5 offset-sm-1 col-md-3 mx-auto col-lg-3 mx-1 my-2 border summary-card">
                                    <p>{parseInt(this.loginState.level) == 0?"My Jobs":"Open Jobs"}</p>
                                    <p className="text-success text-center fw-bold text-medium">{Commons.thousandSeparator(this.state.myJobsCount)}</p>
        
                                </div>
                                </div>
                              
                            <div className="col-md-12 col-lg-12 my-2 mx-auto shadow row">
                                <div id="chart2" className="col-md-4 ms-sm-auto col-lg-4 my-4 py-4 mx-1">
                                    <h5>Jobs Applied</h5>
                                <Pie options={{responsive:true,datalabels:{display:true,anchor:'end'},maintainAspecRatio:true}} data={{labels:["Advertised","Applied","Shortlisted"],datasets:[{label:"Jobs that I applied for",data:this.state.pieData,backgroundColor: ['#4d628b','rgb(255, 99, 132)','rgb(25, 219, 32)'],borderColor: ['rgba(54, 162, 235, 0.2)','rgba(255, 99, 132, 0.2)']}]}} 
                                />
                                </div>
                                <div id="chart3" className="col-md-6 ms-sm-auto col-lg-6 my-4 mx-1 py-4">
                                <h5>Advertised Jobs</h5>
                               <Line data={{labels:Commons.generateMonths(),datasets:[{label:"Advertised Jobs",data:this.state.barData,backgroundColor: ['rgb(255, 99, 132)'],borderColor: ['rgba(255, 99, 132, 0.2)'],}]}} 
                               options={{scales: {}}} />
                            </div>
                            </div>
                           
                            </>}
                        </main>
                    </div>
                </div>
            </div>
            )
            
        }
        return(<LoginForm onAuth={(user=>this.handleAuth(user))} target={"/dashboard"}/>)
    }
    
}
export default Dashboard;