import React from "react";
import logo from "../logo.png";
import commons from "../utils/commons";
import Spinner from "./spinner";
class ResetPasswordForm extends React.Component{

    constructor(props){
        super(props);
        
        this.state={
            isLoading:false,feedback:"",hasError:false
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit = (e)=>{
        e.preventDefault();
        this.setState({isLoading:true});
        let url = commons.getURL("login");
        let email = document.getElementById("email").value;
        let body = {email:email,reset:true};
        let options={
            method:"POST",
            body:JSON.stringify(body),
            headers:{'Content-type':'application/json'}
        }
        fetch(url,options)
        .then(res=>res.json())
        .then(result=>{
            console.log("result:",result);
            this.setState({hasError:!result.status,feedback:result.message,isLoading:false})
        })
        .catch(e=>{
            console.log("e: ",e);
            this.setState({hasError:true,feedback:"Pole, kuna tatizo limetokea",isLoading:false})
        });
    }
    render(){
        // if(!this.state.isAuthenticated){
        return(
            <main className="m-4 mx-auto ">
                <img src={logo} alt="logo" className="front_logo"/>
                <form className="mx-auto px-2 col-md-8 col-lg-8 col-sm-8 offset-2 offset-sm-1" onSubmit={this.handleSubmit}>
                    <div className="primary-text my-3">Reset Password</div>
                    {this.state.feedback.length > 0 ? <div className={"form-group my-2 col-md-4 col-lg-4 col-sm-10 offset-lg-3 offset-md-3 offset-sm-1 "+(this.state.hasError ? "alert-danger" :"alert-success")}>{this.state.feedback}</div>:null}
                    <div className="form-group my-2 col-md-4 col-lg-4 col-sm-10 offset-lg-4 offset-md-4 offset-sm-1">
                        {/* <label for="email" className="text-left">Username/E-mail address</label> */}
                        <input type="email" id="email" name="email" placeholder="Email" className="text-center form-control" required={true}/>
                    </div>
                    
                    <div className="form-group my-2 col-md-4 col-lg-4 col-sm-10 offset-lg-4 offset-md-4 offset-sm-1">
                        {this.state.isLoading ? <Spinner isShowing={this.state.isLoading} size="spinner"/> : <input type="submit" value="SUBMIT" className="btn-success form-control" onClick={this.handleSubmit}/>}
                    </div>
                    <div className="form-group my-2 col-md-4 col-lg-4 col-sm-10 offset-lg-4 offset-md-4 offset-sm-1 text-left">
                        <a href="/">Back to login</a>    
                    </div>
                </form>

            </main>)
        // }
    }
}
export default ResetPasswordForm;