
import './App.css';
import React from 'react';
import CustomRouter from './components/router';

class App extends React.Component {
  constructor(props){
    super(props);
    this.state = {hasError:false,isAuth:false}
    // this.handleAuth = this.handleAuth.bind(this);
    this.storage = window.localStorage;
  }
  
  render(){
   
    return (
      <div className="App">
        {/* <ErrorBoundary FallbackComponent={ErrorComponent} onError={(e,msg)=>{}}> */}
        <CustomRouter basename="hr" />
        {/* </ErrorBoundary> */}
      </div>
    );
  }
  
}

export default App;