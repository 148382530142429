import React, { useEffect,useState } from "react";
// import { useState } from "react/cjs/react.development";
import commons from "../utils/commons";
import SkillPill from "./skill_pill";

const SkillForm=(props)=>{
    var storage = window.localStorage;
    var storedData = JSON.parse(storage.getItem("data"));
    var loginState = JSON.parse(window.sessionStorage.getItem("loginState"));
   
    const [feedback,setFeedback] = useState("");
    const [hasError,setHasError] = useState(false);
    const [isEdit,setIsEdit] = useState(props.data != null);
    const [data,setData] = useState({name:"",description:""});
    
    const [skills,setSkills] = useState(storedData.skills ? storedData.skills :[]);
    
    const handleSubmit=(e)=>{
        e.preventDefault();
        
        var fd = {};
        if(isEdit) fd.id = data.id;
        fd.name = document.getElementById("skill_name").value;
        fd.description = document.getElementById("description").value;
        
        saveSkill(fd).then(result=>{
        setHasError(!result.status);
        setFeedback(result.message);
        if(result.status){
            setFeedback(result.message);
            storedData.skills = result.skills;
            storage.setItem("data",JSON.stringify(storedData));
            storedData = JSON.parse(storage.getItem("data"));
            setSkills(result.skills);
            props.onSave(result.skills);
            handleCancelForm();
        }
        
       }).catch(e=>{
            setHasError(true);
            setFeedback(e)
       })
       .finally(()=>{
           e.target.reset();
       });
    }
    const saveSkill= (ed)=>{
        
        return new Promise((resolve,reject)=>{
            
            var options = {
                body:JSON.stringify({auth:loginState,data:ed}),
                method:"POST",headers:{'Content-type':'application/json'}
            }
            let url = commons.getURL("skills")+"/"+(!isEdit ? "create.php":"update.php");
            fetch(url,options)
                    .then(res=>res.json())
                    .then(result=>{
                        resolve(result);
                    })
                    .catch(e=>{
                        console.log("err: ",e);
                        reject("Oops! Something went wrong!");
                    })
        });
}
    const selectSkill=(id)=>{
        let skill = skills.find(s=>s.id == id);
        console.log("skill: ",skill);
        const name = document.getElementById("skill_name");
        const desc = document.getElementById("description");
        name.value = skill.name;
        desc.value = skill.description;
        setData(skill);
        setIsEdit(true);
        
    }
    const handleCancelForm=()=>{
        props.onCancel("skills")
    }
    const removeSkill=(id)=>{
        let item = skills.find(s=>s.id == id);
        deleteRecord(item).then(result=>{
            setHasError(!result.status);
            setFeedback(result.message);
            if(result.status){
                storedData.skills = result.skills;
                storage.setItem("data",JSON.stringify(storedData));
                storedData = JSON.parse(storage.getItem("data"));
                setSkills(result.skills);
                props.onSave(storedData.skills);
                handleCancelForm();
            }
        })
        .catch(e=>{
            setHasError(true);
            setFeedback(e);
        })
    }
    const handleChange=(e)=>{
        let sk = data;
        let val = e.target.value;
        if(e.target.id == "skill_name"){
            sk.name = val;
        }
        if(e.target.id == "description"){
            sk.description = val
        }
        setData(sk);
    }
  const deleteRecord=(item)=>{
      return new Promise((resolve,reject)=>{
           if(window.confirm("Are you sure you want to delete this skill?")){
                var options = {
                    body:JSON.stringify({auth:loginState,data:item}),
                    method:"POST",headers:{'Content-type':'application/json'}
                }
                let url = commons.getURL("skills")+"/delete.php";
                fetch(url,options)
                    .then(res=>res.json())
                    .then(result=>{
                        resolve(result);
                    })
                    .catch(e=>{
                        console.log("err: ",e);
                        reject("Oops!, Something went wrong");
                    })    
            }
      })
     }
     const reset=()=>{
         let sk = {name:"",description:""}
         setData(sk);
         
     }
    return(<>
    <form id="skill_form" encType="multipart/form-data" onSubmit={handleSubmit} className=" offset-md-1 border border-secondary bg-light col-md-10 col-lg-10 col-sm-12">
    <div className="text-bold m-2 text-right text-success">Add Skill</div>
    {feedback.length > 0 ? <div className={"my-2 "+(hasError ? "alert-danger" : "alert-success")}>{feedback}</div>:null}
   
    <div className="offset-1 form-group d-flex flex-column align-items-start justify-content-start col-md-10 col-lg-10 col-sm-10">
        <label htmlFor="skill_name">Name</label>
        <input className="form-control" id="skill_name" name="skill_name" required placeholder="Enter name" defaultValue={isEdit ? data.name:""} onChange={(e)=>handleChange(e)}/>
       
    </div>
    <div className="py-1 offset-1 d-flex flex-column align-items-start justify-content-start col-md-10 col-lg-10 col-sm-8">
        <label htmlFor="description">Description</label>
        <input type="text" className="form-control text-left" id="description" name="description" placeholder="Enter short description" defaultValue={isEdit ? data.description:""} onChange={(e)=>handleChange(e)}/>
    </div>
    <div className="py-1 offset-1 d-flex flex-row align-items-start justify-content-start col-md-10 col-lg-10 col-sm-8">
        <input className="col-md-3 col-lg-2 col-sm-12 btn bg-primary text-white" type="submit" defaultValue="SAVE"/>
       
        <input className="mx-2 text-primary col-md-3 col-lg-2 col-sm-12 btn" type="BUTTON" onClick={()=>reset()} defaultValue="CANCEL"/>
    </div>
</form>
    <div className="d-flex flex-row flex-wrap my-2 col-md-10 col-lg-10 col-sm-10 offset-1">
        {skills.map(item=>{
            return <SkillPill removable={true} id={item.id} name={item.name} selected={false} key={item.id} onRemove={(id)=>removeSkill(id)} onSelect={(id)=>selectSkill(id)}/>
        })}
    
    </div>
</>)
}
export default SkillForm;