import React, { useEffect,useState } from "react";
import Spinner from "./spinner";
import Commons from "../utils/commons";

const JobDetail=(props)=>{
    var storage = window.localStorage;
    var storedData = JSON.parse(storage.getItem("data"));
    var loginState = JSON.parse(window.sessionStorage.getItem("loginState"));
    const regionData = Commons.getRegionalData();
    const [feedback,setFeedback] = useState("");
    const [hasError,setHasError] = useState(false);
    const [isLoading,setIsLoading] = useState(false);
    const [isApplied,setIsApplied] = useState(false);
    let x = new Date(parseInt(props.job.deadline));
    let deadline = Commons.getDays()[x.getDay()]+ " "+Commons.generateMonths()[x.getMonth()]+" "+x.getDate()+", "+x.getFullYear();
    let myApp = storedData.applications.filter(a=>a.job_id == props.job.id);
    // if(myApp.length > 0) setIsApplied(true);
    useEffect(()=>{
        setIsApplied(myApp.length >0);
    })
    const handleCancelForm=(tag)=>{
        props.onCancel(tag);
    }
    const apply=()=>{
        sendApplication().then(result=>{
            setHasError(!result.status);
            setFeedback(result.message);
            if(result.status){
                storedData.applications = result.applications;
                storage.setItem("data",JSON.stringify(storedData));
                storedData = JSON.parse(storage.getItem("data"));
                setIsApplied(true)
            }
        })
        .catch(e=>{
            setHasError(true);
            setFeedback(e);
        })
    }
    const sendApplication=()=>{
        setIsLoading(true);
        return new Promise((resolve,reject)=>{
            let application = {};
            application.job_id = props.job.id;
            application.user_id = loginState.user_id;
            let body = JSON.stringify({data:application,auth:loginState});
            fetch(Commons.getURL("applications")+"/create.php",{
                method:"POST",body:body,headers:{"Content-type":"application/json"}
            })
            .then(res=>res.json())
            .then(result=>{
                setIsLoading(false);
                resolve(result);
            })
            .catch(e=>{
                setIsLoading(false);
                console.error("JobDetail.apply(): ",e);
                reject("Oops! Something went wrong")
            })
        })
        
        
    }
    return(<form encType="multipart/form-data" className="border border-secondary bg-light p-3 my-1 col-md-8 col-lg-8 col-sm-10 offset-md-2 offset-lg-2 offset-sm-1 row">
    <div className="text-medium py-2">Job Details</div>
    {feedback.length > 0 ? <div className={"py-2 my-2 "+(hasError ? "alert-danger" : "alert-success")}>{feedback}</div>:null}
    
    <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
        <label htmlFor="position">Job Position/Title *</label>
        <div className="form-control border text-left" id="position" name="position" >{props.job.title}</div>
       
    </div>
    <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
        <label htmlFor="experience">Experience (Years)</label>
        <div className="form-control border text-left" id="experience" name="experience">{props.job.experience}</div>
           
    </div>
    <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
        <label htmlFor="location">Location</label>
        <div className="form-control border text-left" id="location" name="location">{props.job.location.substr(0,1).toUpperCase()+props.job.location.substr(1).replaceAll("_"," ")}</div>
        
    </div>
    <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
        <label htmlFor="employment">Type of Employment</label>
        <div className="form-control border text-left"  id="employment" name="employment">{props.job.employment}</div>
       
    </div>
    {props.job.institution ?
    <div className="my-2 d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
        <label htmlFor="institution">Institution</label>
        <div className="form-control border text-left" id="institution" name="institution">{props.job.institution}</div>
    </div>:null}
    {props.job.salary?<div className="my-2 d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
        <label htmlFor="salary">Salary</label>
        <div className="form-control border text-left" id="salary" name="salary">TSh. {Commons.thousandSeparator(props.job.salary)}</div>
    </div>:null}
   
    <div className="my-2 d-flex flex-column align-items-start justify-content-start col-md-12 col-lg-12">
        <label htmlFor="description">Description</label>
        <div className="form-control border text-left" id="description" name="description" style={{height:"auto"}}>{props.job.description}</div>
    </div>
   
    <div className="my-2 d-flex flex-column align-items-start justify-content-start col-md-12 col-lg-12">
        <label htmlFor="qualification">Qualifications</label>
        <div className="form-control border text-left"  id="qualification" name="qualification" style={{height:"auto  "}}><ol>{
        props.job.qualification.split("\n").map((l,i)=>{
            return <li key={i}>{l}</li>
        })}</ol></div>
    </div>
   
   
    <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-12 col-md-12">
        <label htmlFor="duties">Duties and Responsibilities</label>
        <div className="form-control border text-left"  id="duties" name="duties"  style={{height:"auto  "}}><ol>{
        props.job.duties.split("\n").map((l,i)=>{
            return <li key={i}>{l}</li>
        })}</ol></div>
    </div>
    <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
        <label htmlFor="address">Application Address</label>
        <div className="form-control border text-left"  id="address" name="address">{props.job.address}</div>
    </div>
    <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
        <label htmlFor="deadline">Application Deadline</label>
        <div className={"form-control border text-left"} id="deadline" name="deadline" >{deadline}</div>
    </div>
    {props.job.attachment.length>0 ?
    <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
        <label htmlFor="attachment">Attachment</label>
        <div className={"form-control border text-left"} id="attachment" name="attachment" ><a href={Commons.getURL("jobs")+"/docs/"+props.job.attachment} target="_blank">View document</a></div>
    </div>:null}
    <div className="my-2 form-group d-flex align-items-center justify-content-between flex-row">
        {loginState.level != Commons.LEVEL_ADMIN?(isLoading ? <Spinner isShowing={true} size="spinner"/>:(isApplied ? <span className="text-success">You already applied to this job</span>:<input className="col-md-3 col-lg-2 col-sm-6 btn bg-primary text-white" type="button" value="APPLY" onClick={()=>apply()}/>)):
        <input onClick={()=>handleCancelForm("job_edit")} className="col-md-3 col-lg-2 col-sm-6 btn bg-primary text-white" type="button" value="EDIT"/>}
        <input onClick={()=>handleCancelForm("jobs")} className="text-primary col-md-3 col-lg-2 col-sm-6 btn" type="button" value="CANCEL"/>
    </div>
</form>
)
}
export default JobDetail;