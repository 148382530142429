import React from "react";
import logo from "../logo.png";
import commons from "../utils/commons";
import { useEffect,useState } from "react";
import SkillPill from "../components/skill_pill";

const UserDetail=(props)=>{
    var storage = window.localStorage;
    var loginState = JSON.parse(window.sessionStorage.getItem('loginState'));
    var user = props.user;
    var storedData = JSON.parse(storage.getItem("data"));
    const regionData = commons.getRegionalData();
    // var regions = regionData[parseInt(user.kanda)].regions.filter(r=>r.key == user.mkoa);
    const [data,setData] = useState([]);
    const [skills,setSkills] = useState([]);
    // var wilayas = (regions.length > 0) ? regions[0].districts:[];
    const handleCancelForm=(tag)=>{
        props.onCancel(tag)
    }
    const selectSkill=(skill)=>{

    }
    useEffect(()=>{
        fetch(commons.getURL("data")+"/profile.php?cid="+user.user_id,{body:JSON.stringify({auth:loginState}),method:"POST",headers:{"Content-type":"application/json"}}).then(res=>res.json())
        .then(result=>{
            console.log("res: ",result);
            if(result.status){
                storedData.profile.education = result.education;
                storedData.profile.work = result.work;
                storedData.profile.user_skills = result.user_skills;
                setData(storedData.profile);
                setSkills(storedData.skills);
                updateData(storedData);
            }
        })
        .catch(e=>{
            console.log("profile: ",e);
        })
    },[]);
    const updateData=(data)=>{
        storage.setItem("data",JSON.stringify(data));
        storedData = JSON.parse(storage.getItem("data"));
    }
    const expand=(event)=>{
        let target = event.target.id.replace("_exp","_detail");
        if(target){
           let div = document.getElementById(target);
            div.classList.toggle("hidden");
        
        }
        

    }

    return(<main className="col-md-10 ms-sm-auto col-lg-10 px-md-4 mx-auto my-4">
    <div className="border border-secondary bg-light p-1 my-1 col-md-8 col-lg-8 col-sm-10 offset-md-2 offset-lg-2 offset-sm-1 row">
    <div className="text-medium py-2 "><img className="front_logo" src={logo}/><p></p>
    <span className="text-center text-medium">{user.fname +" "+user.lname}</span><p className="text-small text-primary bold">{
        data.work && data.work.length >0 && data.work.filter(w=>w.year_end == -1).length >0 ? "Currently Employed":"Currently Unemployed"}</p>
    <span className="text-center text-small">Phone: {user.phone}&nbsp;&#124;&nbsp;</span> 
    <span className="text-center text-small">E-mail: {user.email}&nbsp;&#124;&nbsp;</span>
    <span className="text-center text-small">Current Location: {user.location}</span>
    </div>
   </div> 
   <div className="border border-secondary bg-light py-3 pt-0 my-1 col-md-8 col-lg-8 col-sm-10 offset-md-2 offset-lg-2 offset-sm-1 row">
        
            <div className="text-white text-medium bg-dark my-2 mt-0 d-flex flex-row align-items-center justify-content-between col-md-12 col-lg-12">
                <span>Skills</span>{data.user_skills && data.user_skills.length > 0 ?<span className="text-white material-icons" id="skill_exp" onClick={expand}>expand_less</span>:null}</div>
            {data.user_skills && data.user_skills.length > 0?
            <div id="skill_detail" className="d-flex flex-row flex-wrap justify-content-start">
                {data.user_skills.map(d=>{
                    return(
                        <SkillPill id={d.id} name={d.name} key={d.id} selected={true}/>
                        )
                })}
               
            </div>:null}
            
        
    </div>
    <div className="border border-secondary bg-light py-3 pt-0 my-1 col-md-8 col-lg-8 col-sm-10 offset-md-2 offset-lg-2 offset-sm-1 row">
        
            <div className="text-white text-medium bg-dark my-2 mt-0 d-flex flex-row align-items-center justify-content-between col-md-12 col-lg-12">
                <span>Work Experience</span>{data.work && data.work.length > 0 ?<span className="text-white material-icons" id="work_exp" onClick={expand}>expand_less</span>:null}</div>
            {data.work && data.work.length > 0?
            <div id="work_detail">
                {data.work.map(d=>{
                    return(<div key={d.id} className="list-item" >
                        <span className="material-icons text-success">work</span>&nbsp;
                        <b>{d.title+" at "+d.institution+": "}</b>
                        <span>&nbsp;{d.year_start+ " - "+(d.year_end == -1 ? "Present" : d.year_end)}</span>
                    </div>)
                })}
            </div>:null}
            
        
    </div>
<div className="border border-secondary bg-light py-3 pt-0 my-1 col-md-8 col-lg-8 col-sm-10 offset-md-2 offset-lg-2 offset-sm-1 row">
    <div className="text-white text-medium bg-dark my-2 mt-0 d-flex flex-row align-items-center justify-content-between col-md-12 col-lg-12"><span>Education Background</span><span className="text-white material-icons" id="education_exp" onClick={expand}>expand_less</span></div>
    {data.education && data.education.length > 0?
        <div id="education_detail">
            {data.education.map(d=>{
                return(<div key={d.id} className="list-item">
                    <span className="material-icons text-success">school</span>
                    <b>{d.year+ " "+d.institution+"("+d.country+"): "}</b>
                    <span>&nbsp;{d.title+", "+commons.getAwards()[d.award-1]}</span>
                </div>)
            })}
        </div>:null}
    
</div>
</main>
)
}
export default UserDetail;