import React from "react";
import Commons from "../utils/commons";

class SideBar extends React.Component{
    constructor(props){
        super(props);

        this.state ={
            items:[],
            selected:props.selected
        }
        this.loginState = JSON.parse(window.sessionStorage.getItem("loginState"));  
        this.activate = this.activate.bind(this);
    }
    activate=(e)=>{
        if(e.target){
            this.setState({selected:e.target.id},()=>{
            console.log("selected: ",this.state.selected)
            window.location.pathname = "/"+this.state.selected;
        })
        }
        
    }
   
    render(){
        return(
            <nav id="sidebarMenu" className="col-md-2 col-lg-2 d-md-block bg-theme sidebar collapse">
            <div className="position-sticky pt-3">
                <ul className="nav flex-column">
                    <li className="nav-item">
                        <a id="dashboard" className={"nav-link "+(this.state.selected == "dashboard" ? "active":"")} href="/dashboard" onClick={(e)=>this.activate(e)}>
                            <span className="material-icons">home</span>Dashboard
                        </a>
                    </li>
                    <li className="nav-item">
                        <a id="jobs" className={"nav-link "+(this.state.selected == "jobs" ? "active":"")}  href="/jobs" onClick={(e)=>this.activate(e)}>
                            <span className="material-icons">work</span>
                            Jobs
                        </a>
                    </li>
                    <li className="nav-item">
                        <a id="applications" className={"nav-link "+(this.state.selected == "applications" ? "active":"")}  href="/applications" onClick={(e)=>this.activate(e)}>
                            <span className="material-icons">assignment</span>
                            Applications
                        </a>
                    </li>
                    {this.loginState.level == Commons.LEVEL_ADMIN ?
                    <>
                    <li className="nav-item">
                        <a id="reports" className={"nav-link "+(this.state.selected == "reports" ? "active":"")}  href="/reports" onClick={(e)=>this.activate(e)}>
                            <span className="material-icons">assessment</span>
                            Reports
                        </a>
                    </li>
                    
                    <li className="nav-item">
                        <a id="candidat" className={"nav-link "+(this.state.selected == "candidat" ? "active":"")}  href="/candidates" onClick={(e)=>this.activate(e)}>
                            <span className="material-icons">account_circle</span>
                            Candidates
                        </a>
                    </li>
                    <li className="nav-item">
                        <a id="settings" className={"nav-link "+(this.state.selected == "settings" ? "active":"")}  href="/settings" onClick={(e)=>this.activate(e)}>
                            <span className="material-icons">settings</span>
                            Settings
                        </a>
                    </li>
                    </>:
                    <li className="nav-item">
                        <a id="profile" className={"nav-link "+(this.state.selected == "profile" ? "active":"")}  href="/profile" onClick={(e)=>this.activate(e)}>
                            <span className="material-icons">manage_accounts</span>
                            Profile
                        </a>
                    </li>}
                </ul>
            </div>
        </nav>
        )
    }
    
}
export default SideBar;