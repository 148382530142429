import React from 'react';
const ListHeadRow = (props)=>{
    var data = props.data;
    var width = "col-lg-2 col-md-2";
    if(data.length > 6) width = "col-lg-1 col-md-1";
    if(data.length == 4) width = "col-lg-3 col-md-3";
    if(data.length == 3) width = "col-lg-4 col-md-4";
    if(data.length == 6) width = "col-lg-2 col-md-2";
    let align = "text-left";

    return(
        <div className={"mx-auto row col-md-12 col-lg-12 d-flex flex-row justify-content-start align-items-center text-left bg-secondary"}>
            {data.length > 0 ? (
            data.map((d,i)=>{
            
                if(d.key == "position") return(<div key={i} className={"col-lg-3 col-md-3 text-left head-row" }>{d.label}</div>)
                else return(<div key={i} className={width+ " head-row "+align }>{d.label}</div>)
             
            })  
            ):null}
        </div>
                    
    )
}
export default ListHeadRow;