import React, { useEffect,useState } from "react";
import Spinner from "./spinner";
import Commons from "../utils/commons";
import isFloat from "validator/lib/isFloat";

const ApplicationDetail=(props)=>{
    var storage = window.localStorage;
    var storedData = JSON.parse(storage.getItem("data"));
    var loginState = JSON.parse(window.sessionStorage.getItem("loginState"));
    const regionData = Commons.getRegionalData();
    const [feedback,setFeedback] = useState("");
    const [hasError,setHasError] = useState(false);
    const [isLoading,setIsLoading] = useState(false);
    const [hasExpired,setHasExpired] = useState(false);
    const [data,setData] = useState(props.application);
    const [fileChanged,setFileChanged] = useState(false);
    let now = Date.now();
    let candidate = (loginState.level == Commons.LEVEL_ADMIN) ? storedData.users.find(u=>u.user_id == data.user_id):loginState;
    let job = storedData.jobs.find(j=>j.id == data.job_id);
    let x = new Date(parseInt(data.date_created));
    let y = new Date(parseInt(job.deadline));
    let date_applied = Commons.getDays()[x.getDay()]+ " "+Commons.generateMonths()[x.getMonth()]+" "+x.getDate()+", "+x.getFullYear();
    
    let deadline = Commons.getDays()[y.getDay()]+ " "+Commons.generateMonths()[y.getMonth()]+" "+y.getDate()+", "+y.getFullYear();
    
    useEffect(()=>{
        setHasExpired(now > job.deadline);
    })
    const sendUpdate=()=>{
        let app = data;
        setIsLoading(true);
        let body = JSON.stringify({auth:loginState,data:app});
        fetch(Commons.getURL("applications")+"/update.php",{
            method:"POST",body:body,headers:{"Content-type":"application/json"}
        }).then(res=>res.json())
        .then(result=>{
            setHasError(!result.status);
            setFeedback(result.message);
            if(result.status){
                storedData.applications = result.applications;
                storage.setItem("data",JSON.stringify(storedData));
                storedData = JSON.parse(storage.getItem("data"));
                setData(result.applications.find(a=>a.id == data.id));
                setFileChanged(true);
            }
        })
        .catch(e=>{
            setHasError(true);
            setFeedback("Oops! Something went wrong")
        })
        .finally(()=>{
            setIsLoading(false)
        })
    }
    const handleFileUpload=(e)=>{
        e.preventDefault();
        var file;
        let att = e.target.files[0];
        if(att){
            let reader = new FileReader();
            reader.addEventListener('load',()=>{
                file = reader.result;
                data.file = file;
            
                setData(data);
                sendUpdate();
            },false);
            reader.readAsDataURL(att);
        }
    }
    const handleCancelForm=(tag)=>{
        props.onCancel(tag);
    }
   const handleStatusChange=(e)=>{
        let status = e.target.value;
        let prevStatus = data.status;
        if(status == 5){
            let conf =window.confirm("Are you sure you want to withdraw this application?");
            if(conf){
                data.status = status
                setData(data);
                sendUpdate();
            }
            else{
                e.target.value = prevStatus;
            }
            
        }
        else {
            if(status == 4){
                let comment = prompt("Please enter a reason");
                data.comment = comment;
            }
            data.status = status
            setData(data);
            sendUpdate();
        }
   }
    return(<form encType="multipart/form-data" className="border border-secondary bg-light p-3 my-1 col-md-8 col-lg-8 col-sm-10 offset-md-2 offset-lg-2 offset-sm-1 row">
    <div className="text-medium py-2">Application Detail</div>
    {feedback.length > 0 ? <div className={"py-2 my-2 "+(hasError ? "alert-danger" : "alert-success")}>{feedback}</div>:null}
    <div className="mb-5 form-group d-flex flex-column align-items-center justify-content-start col-md-12 col-lg-12">
        
        <div className={"form-control border border-"+Commons.getStatusColor(data.status)+" text-center bold text-"+Commons.getStatusColor(data.status)} >Application Status: <b>{Commons.getStatusText(data.status)}</b></div>
       
    </div>
    <div className="form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
        <label htmlFor="candidate">Applicant's Name</label>
        <div className="form-control border text-left" id="candidate" name="candidate" >{candidate.fname+ " "+candidate.lname}</div>
       
    </div>
    <div className="form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
        <label htmlFor="deadline">Date Applied</label>
        <div className={"form-control border text-left"} id="deadline" name="deadline" >{date_applied}</div>
    </div>
    <div className="form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
        <label htmlFor="position">Position</label>
        <div className="form-control border text-left" id="position" name="position" >{job.title}</div>
       
    </div>
    <div className="form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
        <label htmlFor="experience">Experience (Years)</label>
        <div className="form-control border text-left" id="experience" name="experience">{job.experience}</div>
           
    </div>
    <div className="form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
        <label htmlFor="location">Location</label>
        <div className="form-control border text-left" id="location" name="location">{job.location.substr(0,1).toUpperCase()+job.location.substr(1).replaceAll("_"," ")}</div>
        
    </div>
   
    <div className="form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
        <label htmlFor="employment">Type of Employment</label>
        <div className="form-control border text-left"  id="employment" name="employment">{job.employment}</div>
       
    </div>
   
    <div className="form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
        <label htmlFor="deadline">Application Deadline</label>
        <div className={"form-control border text-left"} id="deadline" name="deadline" >{deadline}</div>
    </div>
    <div className="form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
        <label htmlFor="document">Attachment</label>
        <div className={"form-control border text-left"} id="document" name="document" >
            {data.document_link.length > 0 || fileChanged ? <a id="doc_preview" target="_blank" href={Commons.getURL("applications")+"/docs/"+data.document_link}>View Document</a>:(loginState.level != Commons.LEVEL_ADMIN && !hasExpired ?<input type="file" id="upload_doc" name="upload_doc" onChange={handleFileUpload} accept="application/pdf"/>:"No attachment")}
        </div>
    </div>
    {data.document_link.length > 0 && data.status < 3 && loginState.level != Commons.LEVEL_ADMIN? <div className="form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
        <label htmlFor="document_">Upload New Attachment</label>
        <div className={"form-control border text-left"} id="document_" name="document_" >
            <input type="file" id="upload_doc" name="upload_doc" onChange={handleFileUpload} accept="application/pdf"/>
        </div>
    </div>:null}
    {hasExpired || data.status > 2?null:
    <div className="form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
        <label htmlFor="document">Change Status</label>
        <select className={"form-control"} id="status" name="status" defaultValue={data.status} onChange={handleStatusChange}>
            {loginState.level == Commons.LEVEL_ADMIN ? (Commons.getStata().filter(d=>d.val != Commons.WITHDRAW_STATUS).map((s,i)=>{
                return <option key={s.val} value={s.val}>{s.label}</option>
            })):Commons.getStata().filter((s,i)=>s.label.toLowerCase() == "withdrawn" || s.val==data.status).map((s,i)=>{
                return <option key={s.val} value={s.val}>{s.label}</option>
            })}
        </select>
    </div>}
    <div className="my-2 form-group d-flex align-items-center justify-content-between flex-row">
        {loginState.level != Commons.LEVEL_ADMIN?(isLoading ? <Spinner isShowing={true} size="spinner"/>:(hasExpired ? <span className="text-danger">You cannot edit; deadline passed</span>:null)):
        null}
        <input onClick={()=>handleCancelForm("applicattions")} className="text-primary col-md-3 col-lg-2 col-sm-6 btn" type="button" value="CLOSE"/>
    </div>
</form>
)
}
export default ApplicationDetail;