
class Commons{
    PASSWORD_MIN_LENGTH = 8;
    LEVEL_ADMIN = 1;
    WITHDRAW_STATUS = 5;
    DEFAULT_PORT = "4000";
    BASE_URL = "https://hr.pcs.co.tz";
    URLS = [{endpoint:"roles",path:"api/roles"},{endpoint:"login",path:"api/auth"},{endpoint:"data",path:"api/data"},{endpoint:"users",path:"api/users"},{endpoint:"jobs",path:"api/jobs"},{endpoint:"applications",path:"api/applications"},{endpoint:"education",path:"api/education"},{endpoint:"experience",path:"api/experience"},{endpoint:"skills",path:"api/skills"}];
    getRegionalData = ()=>{
        return [
            {
                "id":0,
                "name":"Bara",
                "regions":[
                            {   "key":"arusha",
                                "name":"Arusha",
                                "districts":[
                                    {
                                        "name":"Arusha CBD",
                                        "wards":[]
                                    },
                                    {
                                        "name":"Arusha",
                                        "wards":[]
                                    },
                                    {
                                        "name":"Karatu",
                                        "wards":[]
                                    },
                                    {
                                        "name":"Longido",
                                        "wards":[]
                                    },{
                                        "name":"Meru",
                                        "wards":[]
                                    },
                                    {
                                        "name":"Monduli",
                                        "wards":[]
                                    },
                                    {
                                        "name":"Ngorongoro",
                                        "wards":[]
                                    }
                                ]
                                },
                            {"key":"dar_es_salaam","name":"Dar es Salaam",
                                "districts":[
                                    {
                                        "name":"Ilala CBD",
                                        "wards":[]
                                    },
                                    {
                                        "name":"Kigamboni",
                                        "wards":[]
                                    },
                                    {
                                        "name":"Kinondoni",
                                        "wards":[]
                                    },
                                    {
                                        "name":"Temeke",
                                        "wards":[]
                                    },
                                    {
                                        "name":"Ubungo",
                                        "wards":[]
                                    }
                                ]
                            },
                            {"key":"dodoma",
                                "name":"Dodoma",
                                "districts":[
                                    {
                                        "name":"Bahi",
                                        "wards":[]
                                    },
                                    {
                                        "name":"Chamwino",
                                        "wards":[]
                                    },
                                    {
                                        "name":"Chemba",
                                        "wards":[]
                                    },
                                    {
                                        "name":"Dodoma",
                                        "wards":[]
                                    },
                                    {
                                        "name":"Kondoa",
                                        "wards":[]
                                    },
                                    {
                                        "name":"Kongwa",
                                        "wards":[]
                                    },
                                    {
                                        "name":"Mpwampwa",
                                        "wards":[]
                                    }
                                ]
                            },
                            {"key":"geita",
                                "name":"Geita",
                                "districts":[
                                    {
                                        "name":"Bukombe",
                                        "wards":[]
                                    },
                                    {
                                        "name":"Chato",
                                        "wards":[]
                                    },
                                    {
                                        "name":"Geita",
                                        "wards":[]
                                    },
                                    {
                                        "name":"Mbogwe",
                                        "wards":[]
                                    },
                                    {
                                        "name":"Nyang'hwale",
                                        "wards":[]
                                    },
                                    
                                ]
                            },
                            {"key":"iringa",
                                "name":"Iringa",
                                "districts":[
                                    {
                                        "name":"Iringa Mjini",
                                        "wards":[]
                                    },
                                    {
                                        "name":"Iringa Vijijini",
                                        "wards":[]
                                    },
                                    {
                                        "name":"Kilolo",
                                        "wards":[]
                                    },
                                    {
                                        "name":"Mafinga",
                                        "wards":[]
                                    },
                                    {
                                        "name":"Mufindi",
                                        "wards":[]
                                    },
                                    
                                ]
                            },
                            {"key":"kagera",
                                "name":"Kagera",
                            "districts":[
                                {
                                    "name":"Biharamulo",
                                    "wards":[]
                                },
                                {
                                    "name":"Bukoba Mjini",
                                    "wards":[]
                                },
                                {
                                    "name":"Bukoba Vijijini",
                                    "wards":[]
                                },
                                {
                                    "name":"Karagwe",
                                    "wards":[]
                                },
                                {
                                    "name":"Kyerwa",
                                    "wards":[]
                                },
                                {
                                    "name":"Missenyi",
                                    "wards":[]
                                },
                                {
                                    "name":"Muleba",
                                    "wards":[]
                                },
                                {
                                    "name":"Ngara",
                                    "wards":[]
                                }
                            ]
                            },
                            
                            {"key":"katavi",
                                "name":"Katavi",
                                "districts":[{
                                    "name":"Mlele","wards":[]
                                },{
                                    "name":"Mpanda Mjini","wards":[]
                                },{
                                    "name":"Mpanda Vijijini","wards":[]
                                },
                            ]
                            },{"key":"kigoma",
                                "name":"Kigoma",
                                "districts":[{
                                    "name":"Buhigwe","wards":[]
                                },{
                                    "name":"Kakonko","wards":[]
                                },{
                                    "name":"Kasulu Mjini","wards":[]
                                },{
                                    "name":"Kasulu Vijijini","wards":[]
                                },{
                                    "name":"Kibondo","wards":[]
                                },{
                                    "name":"Kigoma Mjini","wards":[]
                                },{
                                    "name":"Kigoma Ujiji","wards":[]
                                },{
                                    "name":"Uvinza","wards":[]
                                },
                            ]
                            },
                            {"key":"kilimanjaro",
                                "name":"Kilimanjaro",
                                "districts":[{
                                    "name":"Hai","wards":[]
                                },{
                                    "name":"Moshi Mjini","wards":[]
                                },{
                                    "name":"Moshi Vijijini","wards":[]
                                },{
                                    "name":"Mwanga","wards":[]
                                },{
                                    "name":"Rombo","wards":[]
                                },{
                                    "name":"Same","wards":[]
                                },{
                                    "name":"Siha","wards":[]
                                }
                            ]
                            },
                            
                            {"key":"lindi",
                                "name":"Lindi",
                                "districts":[{
                                    "name":"Kilwa","wards":[]
                                },{
                                    "name":"Lindi","wards":[]
                                },{
                                    "name":"Lindi Vijijini","wards":[]
                                },{
                                    "name":"Liwale","wards":[]
                                },{
                                    "name":"Nachingwea","wards":[]
                                },{
                                    "name":"Ruangwa","wards":[]
                                },{
                                    "name":"Kigoma Ujiji","wards":[]
                                }
                            ]
                            },
                            {"key":"manyara",
                                "name":"Manyara",
                                "districts":[{
                                    "name":"Babati Mjini","wards":[]
                                },{
                                    "name":"Babati Vijijini","wards":[]
                                },{
                                    "name":"Hanang","wards":[]
                                },{
                                    "name":"Kiteto","wards":[]
                                },{
                                    "name":"Mbulu","wards":[]
                                }
                                
                            ]
                            },{"key":"mara",
                                "name":"Mara",
                                "districts":[{
                                    "name":"Bunda","wards":[]
                                },{
                                    "name":"Butiama","wards":[]
                                },{
                                    "name":"Musoma Mjini","wards":[]
                                },{
                                    "name":"Musoma Vijijini","wards":[]
                                },{
                                    "name":"Rorya","wards":[]
                                },{
                                    "name":"Serengeti","wards":[]
                                },{
                                    "name":"Tarime","wards":[]
                                }
                            ]
                            },{"key":"mbeya",
                                "name":"Mbeya",
                                "districts":[{
                                    "name":"Busokelo","wards":[]
                                },{
                                    "name":"Chunya","wards":[]
                                },{
                                    "name":"Kyela","wards":[]
                                },{
                                    "name":"Mbarali","wards":[]
                                },{
                                    "name":"Mbeya CBD","wards":[]
                                },{
                                    "name":"Mbeya Vijijini","wards":[]
                                },{
                                    "name":"Rungwe","wards":[]
                                }
                            ]
                            },{"key":"morogoro",
                                "name":"Morogoro",
                                "districts":[{
                                    "name":"Gairo","wards":[]
                                },{
                                    "name":"Kilombero","wards":[]
                                },{
                                    "name":"Kilosa","wards":[]
                                },{
                                    "name":"Malinyi","wards":[]
                                },{
                                    "name":"Morogoro Mjini","wards":[]
                                },{
                                    "name":"Morogoro Vijijini","wards":[]
                                },{
                                    "name":"Mvomero","wards":[]
                                },{
                                    "name":"Ulanga","wards":[]
                                },
                            ]
                            },
                            {"key":"mtwara",
                                "name":"Mtwara",
                                "districts":[{
                                    "name":"Masasi Mjini","wards":[]
                                },{
                                    "name":"Masasi Vijijini","wards":[]
                                },{
                                    "name":"Mtwara Mjini","wards":[]
                                },{
                                    "name":"Mtwara Vijijini","wards":[]
                                },{
                                    "name":"Nanyumbu","wards":[]
                                },{
                                    "name":"Newala","wards":[]
                                },{
                                    "name":"Tandahimba","wards":[]
                                }
                            ]
                            },
                            {"key":"mwanza",
                                "name":"Mwanza",
                                "districts":[{
                                    "name":"Ilemela","wards":[]
                                },{
                                    "name":"Kwimba","wards":[]
                                },{
                                    "name":"Magu","wards":[]
                                },{
                                    "name":"Misungwi","wards":[]
                                },{
                                    "name":"Nyamagana","wards":[]
                                },{
                                    "name":"Sengerema","wards":[]
                                },{
                                    "name":"Ukerewe","wards":[]
                                }
                            ]
                            },
                            {"key":"njombe",
                                "name":"Njombe",
                                "districts":[{
                                    "name":"Ludewa","wards":[]
                                },{
                                    "name":"Makambako","wards":[]
                                },{
                                    "name":"Makete","wards":[]
                                },{
                                    "name":"Njombe Mjini","wards":[]
                                },{
                                    "name":"Njombe Vijijini","wards":[]
                                },{
                                    "name":"Wanginge","wards":[]
                                }
                            ]
                            },
                            {"key":"pwani",
                                "name":"Pwani",
                                "districts":[{
                                    "name":"Bagamoyo","wards":[]
                                },{
                                    "name":"Kibaha Mjini","wards":[]
                                },{
                                    "name":"Kibaha Vijijini","wards":[]
                                },{
                                    "name":"Kisarawe","wards":[]
                                },{
                                    "name":"Mafia","wards":[]
                                },{
                                    "name":"Mkuranga","wards":[]
                                },{
                                    "name":"Rufiji","wards":[]
                                }
                            ]
                            },
                            {"key":"rukwa",
                                "name":"Rukwa",
                                "districts":[{
                                    "name":"Kalambo","wards":[]
                                },{
                                    "name":"Nkasi","wards":[]
                                },{
                                    "name":"Sumbawanga Mjini","wards":[]
                                },{
                                    "name":"Sumbawanga Vijijini","wards":[]
                                }
                            ]
                            },
                            {"key":"ruvuma",
                                "name":"Ruvuma",
                                "districts":[{
                                    "name":"Mbinga","wards":[]
                                },{
                                    "name":"Namtumbo","wards":[]
                                },{
                                    "name":"Nyasa","wards":[]
                                },{
                                    "name":"Songea Mjini","wards":[]
                                },{
                                    "name":"Songea Vijijini","wards":[]
                                }
                            ]
                            },
                            {"key":"shinyanga",
                                "name":"Shinyanga",
                                "districts":[{
                                    "name":"Kahama Mjini","wards":[]
                                },{
                                    "name":"Kahama Vijijini","wards":[]
                                },{
                                    "name":"Kishapu","wards":[]
                                },{
                                    "name":"Shinyanga Mjini","wards":[]
                                },{
                                    "name":"Shinyanga Vijijini","wards":[]
                                }
                            ]
                            },
                            {"key":"simiyu",
                                "name":"Simiyu",
                                "districts":[{
                                    "name":"Bariadi","wards":[]
                                },{
                                    "name":"Buswega","wards":[]
                                },{
                                    "name":"Itilima","wards":[]
                                },{
                                    "name":"Maswa","wards":[]
                                },{
                                    "name":"Meatu","wards":[]
                                }
                            ]
                            },
                            {"key":"singida",
                                "name":"Singida",
                                "districts":[{
                                    "name":"Ikungi","wards":[]
                                },{
                                    "name":"Iramba","wards":[]
                                },{
                                    "name":"Manyoni","wards":[]
                                },{
                                    "name":"Mkalama","wards":[]
                                },{
                                    "name":"Singida Mjini","wards":[]
                                },{
                                    "name":"Singida Vijijini","wards":[]
                                }
                            ]
                            },
                            {"key":"songwe",
                                "name":"Songwe",
                                "districts":[{
                                    "name":"Ileje","wards":[]
                                },{
                                    "name":"Mbozi","wards":[]
                                },{
                                    "name":"Momba","wards":[]
                                },{
                                    "name":"Songwe","wards":[]
                                }
                            ]
                            },
                            {"key":"tabora",
                                "name":"Tabora",
                                "districts":[{
                                    "name":"Igunga","wards":[]
                                },{
                                    "name":"Kaliua","wards":[]
                                },{
                                    "name":"Nzega","wards":[]
                                },{
                                    "name":"Sikonge","wards":[]
                                },{
                                    "name":"Tabora","wards":[]
                                },{
                                    "name":"Urambo","wards":[]
                                },{
                                    "name":"Uyui","wards":[]
                                }
                            ]
                            },
                            {"key":"tanga",
                                "name":"Tanga",
                                "districts":[{
                                    "name":"Handeni","wards":[]
                                },{
                                    "name":"Kilindi","wards":[]
                                },{
                                    "name":"Korogwe","wards":[]
                                },{
                                    "name":"Lushoto","wards":[]
                                },{
                                    "name":"Muheza","wards":[]
                                },{
                                    "name":"Mkinga","wards":[]
                                },{
                                    "name":"Pangani","wards":[]
                                },{
                                    "name":"Tanga","wards":[]
                                }
                            ]
                            }
                ]
                    },
            {
                "id":1,
                "name":"Zanzibar",
                "regions":[
                    {"key":"kaskazini_pemba",
                        "name":"Kaskazini Pemba",
                        "districts":[{
                            "name":"Micheweni","wards":[]
                        },{
                            "name":"Wete","wards":[]
                        },
                    ]
                    },
                    {"key":"kaskazini_unguja",
                        "name":"Kaskazini Unguja",
                        "districts":[{
                            "name":"Kaskazini A","wards":[]
                        },{
                            "name":"Kaskazini B","wards":[]
                        },
                    ]
                    },
                    {"key":"kusini_pemba",
                        "name":"Kusini Pemba",
                        "districts":[{
                            "name":"Chake chake","wards":[]
                        },{
                            "name":"Mkoani","wards":[]
                        }
                    ]
                    },
                    {"key":"kusini_unguja",
                        "name":"Kusini Unguja",
                        "districts":[{
                            "name":"Kati","wards":[]
                        },{
                            "name":"Kusini","wards":[]
                        }
                    ]
                    },
                    {"key":"mjini_magharibi",
                    "name":"Mjini Magharibi",
                        "districts":[{
                            "name":"Magharibi A","wards":[]
                        },{
                            "name":"Magharibi B","wards":[]
                        }
                    ]
                    },
                ]
            }
            ]
    }
    randomId=()=>{
        var chars=["a,b,c,d,e,f,g,h,i,j,k,l,m,n,o,p,q,r,s,t,u,v,w,x,y,z,0,1,2,3,4,5,6,7,8,9"];
        let result = "";
        for(let i=0;i<6;i++){
            result += chars[Math.floor(Math.random()*chars.length)];
        }
        console.log("random ",result);
        return result;
    }
    getStatusText=(status)=>{
        return this.getStata().find(d=>d.val == status).label;
    }
    getStata=()=>{
        return [{val:0,label:"Received",color:"info"},{val:1,label:"Short listed",color:"warning"},{val:2,label:"Interviewed",color:"primary"},{val:3,label:"Hired",color:"success"},{val:4,label:"Unsuccessful",color:"dark"},{val:5,label:"Withdrawn",color:"danger"}];
    }
    getStatusColor=(status)=>{
        return this.getStata().find(d=>d.val == status).color;
    }
    getAwards=()=>{
        return ["Certificate","Advanced Certificate","Diploma","Advanced Diploma","Bachelor Degree","Post Graduate Diploma","Master Degree","Doctoral Degree"];
    }
    getCountries=()=>{
        return [
            "Afghanistan",
            "Åland Islands",
            "Albania",
            "Algeria",
            "American Samoa",
            "Andorra",
            "Angola",
            "Anguilla",
            "Antarctica",
            "Antigua and Barbuda",
            "Argentina",
            "Armenia",
            "Aruba",
            "Australia",
            "Austria",
            "Azerbaijan",
            "Bahamas (the)",
            "Bahrain",
            "Bangladesh",
            "Barbados",
            "Belarus",
            "Belgium",
            "Belize",
            "Benin",
            "Bermuda",
            "Bhutan",
            "Bolivia",
            "Bonaire, Saint Eustatius and Saba",
            "Bosnia and Herzegovina",
            "Botswana",
            "Bouvet Island",
            "Brazil",
            "British Indian Ocean Territory",
            "Brunei Darussalam",
            "Bulgaria",
            "Burkina Faso",
            "Burundi",
            "Cabo Verde",
            "Cambodia",
            "Cameroon",
            "Canada",
            "Cayman Islands",
            "Central African Republic",
            "Chad",
            "Chile",
            "China",
            "Christmas Island",
            "Cocos Islands",
            "Colombia",
            "Comoros",
            "Congo DR",
            "Congo",
            "Cook Islands",
            "Costa Rica",
            "Croatia",
            "Cuba",
            "Curaçao",
            "Cyprus",
            "Czechia",
            "Côte d'Ivoire",
            "Denmark",
            "Djibouti",
            "Dominica",
            "Dominican Republic",
            "Ecuador",
            "Egypt",
            "El Salvador",
            "Equatorial Guinea",
            "Eritrea",
            "Estonia",
            "Eswatini",
            "Ethiopia",
            "Falkland Islands",
            "Faroe Islands",
            "Fiji",
            "Finland",
            "France",
            "French Guiana",
            "French Polynesia",
            "French Southern Territories",
            "Gabon",
            "Gambia",
            "Georgia",
            "Germany",
            "Ghana",
            "Gibraltar",
            "Greece",
            "Greenland",
            "Grenada",
            "Guadeloupe",
            "Guam",
            "Guatemala",
            "Guernsey",
            "Guinea",
            "Guinea-Bissau",
            "Guyana",
            "Haiti",
            "Heard Island and McDonald Islands",
            "Holy See (the)",
            "Honduras",
            "Hong Kong",
            "Hungary",
            "Iceland",
            "India",
            "Indonesia",
            "Iran",
            "Iraq",
            "Ireland",
            "Isle of Man",
            "Israel",
            "Italy",
            "Jamaica",
            "Japan",
            "Jersey",
            "Jordan",
            "Kazakhstan",
            "Kenya",
            "Kiribati",
            "Korea",
            "Korea (North)",
            "Kuwait",
            "Kyrgyzstan",
            "Lao PDR",
            "Latvia",
            "Lebanon",
            "Lesotho",
            "Liberia",
            "Libya",
            "Liechtenstein",
            "Lithuania",
            "Luxembourg",
            "Macao",
            "Madagascar",
            "Malawi",
            "Malaysia",
            "Maldives",
            "Mali",
            "Malta",
            "Marshall Islands (the)",
            "Martinique",
            "Mauritania",
            "Mauritius",
            "Mayotte",
            "Mexico",
            "Micronesia",
            "Moldova",
            "Monaco",
            "Mongolia",
            "Montenegro",
            "Montserrat",
            "Morocco",
            "Mozambique",
            "Myanmar",
            "Namibia",
            "Nauru",
            "Nepal",
            "Netherlands",
            "New Caledonia",
            "New Zealand",
            "Nicaragua",
            "Niger",
            "Nigeria",
            "Niue",
            "Norfolk Island",
            "Northern Mariana Islands",
            "Norway",
            "Oman",
            "Pakistan",
            "Palau",
            "Palestine, State of",
            "Panama",
            "Papua New Guinea",
            "Paraguay",
            "Peru",
            "Philippines",
            "Pitcairn",
            "Poland",
            "Portugal",
            "Puerto Rico",
            "Qatar",
            "Republic of North Macedonia",
            "Romania",
            "Russian Federation",
            "Rwanda",
            "Réunion",
            "Saint Barthélemy",
            "Saint Helena",
            "Saint Kitts and Nevis",
            "Saint Lucia",
            "Saint Martin",
            "Saint Pierre and Miquelon",
            "Saint Vincent and the Grenadines",
            "Samoa",
            "San Marino",
            "Sao Tome and Principe",
            "Saudi Arabia",
            "Senegal",
            "Serbia",
            "Seychelles",
            "Sierra Leone",
            "Singapore",
            "Slovakia",
            "Slovenia",
            "Solomon Islands",
            "Somalia",
            "South Africa",
            "South Georgia ",
            "South Sudan",
            "Spain",
            "Sri Lanka",
            "Sudan",
            "Suriname",
            "Svalbard and Jan Mayen",
            "Sweden",
            "Switzerland",
            "Syrian Arab Republic",
            "Taiwan",
            "Tajikistan",
            "Tanzania",
            "Thailand",
            "Timor-Leste",
            "Togo",
            "Tokelau",
            "Tonga",
            "Trinidad and Tobago",
            "Tunisia",
            "Turkey",
            "Turkmenistan",
            "Turks and Caicos Islands",
            "Tuvalu",
            "Uganda",
            "Ukraine",
            "United Arab Emirates",
            "United Kingdom",
            "United States of America",
            "Uruguay",
            "Uzbekistan",
            "Vanuatu",
            "Venezuela",
            "Vietnam",
            "Virgin Islands",
            "Wallis and Futuna",
            "Western Sahara",
            "Yemen",
            "Zambia",
            "Zimbabwe"
          ];
    }
    getCandidatesListLabels=()=>{
        var data = JSON.parse(window.localStorage.getItem("data"));
        let result = data.user_fields.map(d=>{
            let k = {key:d.Field,label:(d.Field.substr(0,1).toUpperCase()+d.Field.substr(1)),type:d.Type}
            
            if(d.Field == "fname") k.label = "First Name";
            if(d.Field == "lname") k.label = "Last Name";
            return k;
        })
        return result;
    }
    getApplicationListLabels=()=>{
        var data = JSON.parse(window.localStorage.getItem("data"));
        let result = data.application_fields.map(d=>{
            let k = {key:d.Field,label:(d.Field.substr(0,1).toUpperCase()+d.Field.substr(1)).replaceAll("_"," "),type:d.Type}
            if(d.Field == "job_id") k.label = "Job Position";
            if(d.Field == "user_id") k.label = "Candidate";
            if(k.key == "document_link") k.label = "Attachment";
            return k;
        })
        return result;
    }
    getJobListLabels=()=>{
        var data = JSON.parse(window.localStorage.getItem("data"));
        let result = data.job_fields.map(d=>{
            let k = {key:d.Field,label:(d.Field.substr(0,1).toUpperCase()+d.Field.substr(1)).replaceAll("_"," "),type:d.Type}
            if(k.key == "document_link" || k.key == "attachment") k.label = "Attachment";
            return k;
        })
        return result;
    }
   
    getRoleListLabels=()=>{
        return [{key:"role_name",label:"Jina"},{key:"role_description",label:"Maelezo"},{key:"edit",label:"Hariri"},{key:"delete",label:"Ondoa"}];
    }
    getPreferences=()=>{
        return {
            mipangilio:{
                riba:{label:"Riba",value:10},
                wadhamini:{label:"Idadi ya Wadhamini",value:4},
                tarehe:{label:"Muundo wa tarehe",value:"DDMMYYYY"},
                lugha:{label:"Lugha",value:"Kiswahili"},
            }
        }

    }
    thousandSeparator =(x)=> {
        return (x === undefined || x === null) ? "": x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    validatePhone=(phone)=>{
        let result = true;
        let charSet = ["0","1","2","3","4","5","6","7","8","9"];
        console.log("checking phone number: ",phone);
        if(phone == null) return false;
        if(typeof(phone) != "string") phone = phone.toString();
        if(phone.includes("+")) phone = phone.replaceAll("+","");
        if(phone.includes(" ")) phone = phone.replaceAll(" ","");
        if(phone.includes("-")) phone = phone.replaceAll("-","");
        
        for(let i=0;i<phone.length;i++){
            console.log("n: ",phone[i]);
            if(charSet.indexOf(phone[i]) === -1){
                result = false;
                break;
            }
        }
        if(!result) return result;
        if(phone.length != 12 && phone.length != 10) {
            console.log("phone number invalid length: ",phone.length);
            return false;
        }
        if(phone.length === 10 && phone[0] != "0"){
            console.log("phone number not starting with 0: ",phone[0]);
            return false;
        }
        if(phone.length === 12 && phone.substring(0,3) !== "255"){
            console.log("phone number invalid country code: ",phone.substring(0,3));
            return false;
        }
        return result;
    }
    validatePassword=(password)=>{
        if(password.length < this.PASSWORD_MIN_LENGTH) return false;
        return true;
    }
    groupBy=(data,group=null,withSum=false,sumColumns=[])=>{
        if(group == null || group === -1) return data;
        if(group === "jinsia" || group === "kanda"){
            data = data.map(d=>{
                let nd = d;
                nd.jinsia = parseInt(d.jinsia) === 0 ? "Wanawake" : "Wanaume";
                nd.kanda = parseInt(d.kanda) === 0 ? "Bara" : "Zanzibar";
                return nd;
            })
        }
        if(group == "mkoa"){
            data = data.map(d=>{
                let nd = d;
                let col = this.getRegionalData()[parseInt(d.kanda)].regions.filter(g=>g.key === d.mkoa.toLowerCase().replaceAll(" ","_") || g.name.toLowerCase() === d.mkoa.toLowerCase().replaceAll("_"," "));
                nd.mkoa = col.length > 0 ? col[0].name : d.mkoa;
                return nd;
            })
        }
        if(group === "wilaya"){
            data = data.map(d=>{
                let nd = d;
                // let col = this.getRegionalData()[parseInt(d.kanda)].regions.map(g=>g.districts).filter(d=>d.name.toLowerCase() == d.wilaya.toLowerCase());
                nd.wilaya = d.wilaya.toLowerCase();
                return nd;
            })  
        }
        var res = [];

        var keys=[...new Set(data.map(d=>d[group]))];
        keys.forEach(key=>{
            let p = {};
            let x = data.filter(d=>d[group] === key);
            if(withSum && sumColumns.length > 0){
                sumColumns.forEach(col=>{
                    let sum = 0;
                    x.forEach(xv=>{
                        if(Object.keys(xv).map(k=>k.toLowerCase()).includes(col.toLowerCase())){
                            sum += parseInt(xv[col]);
                        }
                    })
                    p[col] = sum;
                })
            }
            p.key = key;
            p.val = x;
            res.push(p);
        });
        return res;
    }
    getURL=(endpoint)=>{
        if(endpoint) endpoint = endpoint.toLowerCase();
        var URL = this.URLS.filter(u=>u.endpoint.toLowerCase() === endpoint);
        if(URL.length > 0){
            var path = URL[0].path;
            return window.location.origin + "/"+path;
            // return "http://localhost:"+this.DEFAULT_PORT+"/"+path;
        }
        return null;
    }
    //generate years
    //generates a list of year from start to end inclusive; if end is not provided the current year is set
    generateIntegerArray=(start,end=0)=>{
        let result = [];
        if(typeof(end) !== "number" && typeof(start) !=="number"){
            return result;
        }
        if(start < end){
            let diff = end - start;
            for(let i=0;i<=diff;i++){
                result.push(start + i);
            }
        }
        else{
            let diff = start - end;
            for(let i=0;i<=diff;i++){
                result.push(end + i);
            }
        }
        return result;
    }
    
    generateYears=(start,end=null)=>{
        let result = [];
        if(end === null || typeof(end) != "number"){
            end = new Date(Date.now()).getFullYear();
        }
        if(typeof(start) === "number" && start < end){
            for(let i=0;i<=(end-start);i++){
                result.push(end - i);
            }
        }
        return result;
    }

    generateHours=()=>{
        let result = [];
        for(let i=1;i<=12;i++){
            result.push(i);
        }
        return result;
    }
    generateMinutes=()=>{
        let result = [];
        for(let i=0;i<60;i++){
            let m = i;
            if(i < 10) m = "0"+i;
            result.push(m);
        }
        return result;
    }
    generateMonths=()=>{
        return ["Jan","Feb","Mar","Apr","May","Jun","Aug","Sep","Oct","Nov","Dec"];
    }
    getDays=()=>{
        return ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];
    }
}
export default new Commons();