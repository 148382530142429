import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import { Component } from "react";
import LoginForm from "./login_form";
import Dashboard from "./dashboard";
import Users from "./users";
import SignupForm from "./signup_form";
import Applications from "./applications";
import ResetPasswordForm from "./reset";
import Jobs from "./jobs";
import UserDetail from "./user_detail";
import Profile from "./profile";
import SettingsForm from "./settings";
import NotFound from "./404";
class CustomRouter extends React.Component {
  constructor(props){
    super(props);
    this.handleAuth = this.handleAuth.bind(this);
  }
  handleAuth(){
    console.log("hello")
  }
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={LoginForm} />
          <Route exact path="/signin" component={LoginForm} />
          <Route exact path="/signup" component={SignupForm} />
          <Route
            path="/dashboard"
            render={(props) => <Dashboard user={null} {...props} />}
          />
           {/* <Route
            path="/groups"
            render={(props) => <Groups data={null} {...props} />}
          />
            <Route
            path="/members"
            render={(props) => <Members data={null} {...props} />}
          />*/}
           <Route
            exact path="/profile"
            render={(props) => <Profile />}
          /> 
         
         <Route
            exact path="/candidates"
            render={(props) => <Users  />}
          />
         
         <Route
            exact path="/jobs"
            render={(props) => <Jobs />}
          />
         
         <Route
            exact path="/applications"
            render={(props) => <Applications />}
          />
          <Route
            exact path="/settings"
            render={(props) => <SettingsForm />}
          />
          <Route path="/signout" component={LoginForm} />
          <Route path="/password_reset" component={ResetPasswordForm} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    );
  }
}
export default CustomRouter;
