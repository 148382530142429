import React, { useEffect,useState } from "react";
// import { useState } from "react/cjs/react.development";
import commons from "../utils/commons";

const JobForm=(props)=>{
    var storage = window.localStorage;
    var storedData = JSON.parse(storage.getItem("data"));
    var loginState = JSON.parse(window.sessionStorage.getItem("loginState"));
    const regionData = commons.getRegionalData();
    const [feedback,setFeedback] = useState("");
    const [hasError,setHasError] = useState(false);
    const [att,setAtt] = useState();
    
    const handleSubmit=(e)=>{
        e.preventDefault();
        var form = e.target;
        var fd = {};
        fd.id = storedData.jobs.length;
        fd.title = form.position.value;
        fd.experience = form.experience.value;
        fd.location = form.location.value;
        fd.employment = form.employment.value;
        fd.qualification = form.qualification.value;
        fd.description = form.description.value;
        fd.duties = form.duties.value;
        fd.address = form.address.value;
        fd.deadline = Date.parse(form.deadline.value);
        fd.salary = form.salary.value.trim();
        fd.institution = form.institution.value.trim();
        if(att){
            fd.attachment = att;
        }
        console.log("fd1: ",fd);
       
       saveJob(fd).then(result=>{
        console.log("result: ",result);
        setHasError(!result.status);
        setFeedback(result.message);
        if(result.status){
            storedData.jobs = result.jobs;
            storage.setItem("data",JSON.stringify(storedData));
            storedData = JSON.parse(storage.getItem("data"));
            props.onSave(storedData.jobs);
            handleCancelForm("jobs")
        }
        
       }).catch(e=>{
            setHasError(true);
            setFeedback(e)
       });
    }
    const handleUpload=(e)=>{
        let file = e.target.files[0];
        if(file){
            let reader = new FileReader();
            reader.addEventListener("load",()=>{
                setAtt(reader.result);
            },false);
            reader.readAsDataURL(file);
        }
    }
    const saveJob= (job=>{
        return new Promise((resolve,reject)=>{
            var options = {
                body:JSON.stringify({auth:loginState,data:job}),
                method:"POST",headers:{'Content-type':'application/json'}
            }
            fetch(commons.getURL("jobs")+"/create.php",options)
                    .then(res=>res.json())
                    .then(result=>{
                        resolve(result);
                    })
                    .catch(e=>{
                        console.log("err: ",e);
                        reject("Oops! Something went wrong!");
                    })
                    
            })
        })
       
    const handleCancelForm=(tag)=>{
        props.onCancel(tag)
    }
  
    return(<form encType="multipart/form-data" onSubmit={handleSubmit} className="border border-secondary bg-light p-3 my-5 col-md-8 col-lg-8 col-sm-10 offset-md-2 offset-lg-2 offset-sm-1 row">
    <div className="text-medium py-2">Job Posting</div>
    {feedback.length > 0 ? <div className={"my-2 "+(hasError ? "alert-danger" : "alert-success")}>{feedback}</div>:null}
    
    <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
        <label htmlFor="position">Job Position/Title *</label>
        <input className="form-control" id="position" name="position" required placeholder="Position"/>
       
    </div>
    <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
        <label htmlFor="experience">Experience (Years)</label>
        <select className="form-control" id="experience" name="experience" >
            {commons.generateIntegerArray(20,0).map(y=>{
                return (<option key={y}>{y}</option>)
            })}
        </select>
    </div>
    <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
        <label htmlFor="location">Location</label>
        <select className="form-control" id="location" name="location" >
            <option value="remote">Remote</option>
        {regionData[0].regions.map(city=>{
                return(<option key={city.key} value={city.key}>{city.name}</option>)
            })}
        </select>
    </div>
   
    <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
        <label htmlFor="employment">Type of Employment</label>
        <select required={true} className="form-control" id="employment" name="employment">
        <option>Full Time</option> 
        <option>Part Time</option>
        <option>Contract</option>
        <option>Consultant</option>
        </select>
    </div>
    <div className="my-2 d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
        <label htmlFor="institution">Hiring Institution</label>
        <input type="text" className="form-control text-left" id="institution" name="institution"/>
    </div>
    <div className="my-2 d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
        <label htmlFor="salary">Salary</label>
        <input type="text" className="form-control text-left" id="salary" name="salary" />
    </div>
    <div className="my-2 d-flex flex-column align-items-start justify-content-start col-md-12 col-lg-12">
        <label htmlFor="description">Description *</label>
        <textarea required={true} className="form-control" id="description" name="description" ></textarea>
    </div>
   
    <div className="my-2 d-flex flex-column align-items-start justify-content-start col-md-12 col-lg-12">
        <label htmlFor="qualification">Qualifications (Each on new line)</label>
        <textarea required={true} className="form-control" id="qualification" name="qualification" ></textarea>
    </div>
   
   
    <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-12 col-md-12">
        <label htmlFor="duties">Duties and Responsibilities (Each on new line)</label>
        <textarea required={true} className="form-control"  id="duties" name="duties" ></textarea>
    </div>
    <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-12 col-md-12">
        <label htmlFor="address">Application Address</label>
        <textarea required={true} className="form-control"  id="address" name="address" ></textarea>
    </div>
    <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
        <label htmlFor="deadline">Application Deadline</label>
        <input className={"form-control"} type="date" id="deadline" name="deadline" />
    </div>
    <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
        <label htmlFor="attachment">Attachment (PDF only)</label>
        <input className={"form-control"} type="file" id="attachment" name="attachment"  accept="application/pdf" onChange={handleUpload}/>
    </div>
    <div className="my-2 form-group d-flex align-items-center justify-content-between flex-row">
        <input className="col-md-3 col-lg-2 col-sm-6 btn bg-primary text-white" type="submit" value="SUBMIT"/>
        <input onClick={()=>handleCancelForm("jobs")} className="text-primary col-md-3 col-lg-2 col-sm-6 btn" type="button" value="CANCEL"/>
    </div>
</form>
)
}
export default JobForm;