import React from "react";
import { useEffect, useState } from "react/cjs/react.development";
import commons from "../utils/commons";
import LoginForm from "./login_form";
import Header from "./header";
import SideBar from "./side_bar";
import logo from "../logo.png";
import EducationForm from "./education_form";
import WorkForm from "./work_form";
import SkillPill from "./skill_pill";

const Profile=(props)=>{
    var storage = window.localStorage;
    var session = window.sessionStorage;
    var loginState = JSON.parse(session.getItem("loginState"));
    var user = props.user == null ? loginState:props.user;
    var storedData = JSON.parse(storage.getItem("data"));
    const regionData = commons.getRegionalData();
    const [data,setData] = useState(null);
    const [ed,setEd] = useState(null);
    const [work,setWork] = useState(null);
    const [skills,setSkills] = useState(storedData.skills);
    var regions = regionData[0].regions;
    
    const [showEdForm,setShowEdForm] = useState(false);
    const [showWorkForm,setShowWorkForm] = useState(false);
    const handleCancelForm=(tag)=>{
        switch(tag){
            case "education":
                setShowEdForm(false);
                break;
            case "work":
                setShowWorkForm(false);
                break;
            case "skill":
                break;
        }
       
    }
    const handleAuth=(u)=>{
        session.setItem("loginState",JSON.stringify(u));
        loginState = u;
        storedData = JSON.parse(storage.getItem("data"));
        // loadDataFromStorage(storedData)
    }
    const updateWorkData=(d)=>{
        storedData.profile.work = d;
        setData(storedData.profile);
        updateData(storedData);
    }
    const updateEdData=(d)=>{
        storedData.profile.education = d;
        setData(storedData.profile);
        updateData(storedData);
    }
    const editEd=(d)=>{
        setEd(d);
        if(!showEdForm) setShowEdForm(true);
    }
    const editWork=(d)=>{
        setWork(d);
        if(!showWorkForm) setShowWorkForm(true);
    }
    
    useEffect(()=>{
        fetch(commons.getURL("data")+"/profile.php",{body:JSON.stringify({auth:loginState}),method:"POST",headers:{"Content-type":"application/json"}}).then(res=>res.json())
        .then(result=>{
            console.log("res: ",result);
            if(result.status){
                storedData.profile.education = result.education;
                storedData.profile.work = result.work;
                storedData.profile.user_skills = result.user_skills;
                if(result.user_skills.length > 0){
                
                setData(storedData.profile);
                filterSkills(storedData.profile);
                }
                updateData(storedData);
            }
        })
        .catch(e=>{
            console.log("profile: ",e);
        })
    },[]);
    const updateData=(data)=>{
        storage.setItem("data",JSON.stringify(data));
        storedData = JSON.parse(storage.getItem("data"));
    }
    const expand=(event)=>{
        let target = event.target.id.replace("_exp","_detail");
        let div = document.getElementById(target);
        div.classList.toggle("hidden");
        if(div.classList.contains("hidden")) {
            event.target.textContent = "expand_more";
            if(target.includes("education")) setShowEdForm(false); 
            if(target.includes("work")) setShowWorkForm(false); 
        }
        else event.target.textContent = "expand_less";

    }
    const showHideEdForm=()=>{
        setEd(null);
        setShowEdForm(!showEdForm);
    }
    const showHideWorkForm=()=>{
        setWork(null);
        setShowWorkForm(!showWorkForm);
    }
    const selectSkill=(id)=>{
        let ed = storedData.skills.find(s=>s.id = id);
    
        var options = {
            body:JSON.stringify({auth:loginState,data:ed}),
            method:"POST",headers:{'Content-type':'application/json'}
        }
        let url = commons.getURL("skills")+"/create.php";
        fetch(url,options)
                .then(res=>res.json())
                .then(result=>{
                    console.log("select: ",result);
                    storedData.profile.user_skills = result.skills;
                    storage.setItem("data",JSON.stringify(storedData));
                  
                    setData(storedData.profile);
                    filterSkills(storedData.profile);

                })
                .catch(e=>{
                    console.log("err: ",e);
                    // reject("Oops! Something went wrong!");
                })
   }
   const removeSkill=(id)=>{
    let item = data.user_skills.find(s=>s.id == id);
    deleteRecord(item).then(result=>{
        // setHasError(!result.status);
        // setFeedback(result.message);
        if(result.status){
            storedData.profile.user_skills = result.skills;
            storage.setItem("data",JSON.stringify(storedData));
            storedData = JSON.parse(storage.getItem("data"));
            setData(storedData.profile);
            filterSkills(storedData.profile);
        }
    })
    .catch(e=>{
        // setHasError(true);
        // setFeedback(e);
    })
}
const filterSkills=(d)=>{
    let skIds = storedData.skills.map(s=>s.id);
    let uskIds = d.user_skills.map(us=>us.skill_id);
    let myIds = skIds.filter(sid=>uskIds.indexOf(sid) <0);
    let fSkills = storedData.skills.filter(s=>myIds.indexOf(s.id) >=0);
    console.log("sks: ",skIds,uskIds);
    console.log("skds : ",fSkills);
    setSkills(fSkills);
}
const deleteRecord=(item)=>{
    console.log("dr item: ",item);
  return new Promise((resolve,reject)=>{
       if(window.confirm("Are you sure you want to remove this from your skill set?")){
            var options = {
                body:JSON.stringify({auth:loginState,data:item}),
                method:"POST",headers:{'Content-type':'application/json'}
            }
            let url = commons.getURL("skills")+"/delete.php";
            fetch(url,options)
                .then(res=>res.json())
                .then(result=>{
                    console.log("dr: ",result);
                    resolve(result);
                })
                .catch(e=>{
                    console.log("err: ",e);
                    reject("Oops!, Something went wrong");
                })    
        }
  })
 }
    if(loginState){
        return(
            <div>
                <Header />
                <div className="container-fluid">
                    <div className="row">
                        <SideBar selected="profile"/>
                        <main className="col-md-10 ms-sm-auto col-lg-10 px-md-4 mx-auto my-4">
                        
                            <div className="border border-secondary bg-light p-1 my-1 col-md-8 col-lg-8 col-sm-10 offset-md-2 offset-lg-2 offset-sm-1 row">
                            <div className="text-medium py-2 "><img className="front_logo" src={logo}/><p></p>
                            
                            <span className="text-center text-medium">{user.fname +" "+user.lname}</span><p className="text-small text-primary bold">{
                                data && data.work && data.work.length >0 && data.work.filter(w=>w.year_end == -1).length >0 ? "Currently Employed":"Currently Unemployed"}</p>
                            <span className="text-center text-small">Phone: {user.phone}&nbsp;&#124;&nbsp;</span> 
                            <span className="text-center text-small">E-mail: {user.email}&nbsp;&#124;&nbsp;</span>
                            <span className="text-center text-small">Current Location: {user.location}</span>
                            </div>
                           </div> 
                           <div className="border border-secondary bg-light py-3 pt-0 my-1 col-md-8 col-lg-8 col-sm-10 offset-md-2 offset-lg-2 offset-sm-1 row">
                            
                            <div className="text-white text-medium bg-dark my-2 mt-0 d-flex flex-row align-items-center justify-content-between col-md-12 col-lg-12 col-sm-12"><span>Skills</span>{skills && skills.length >= 0 ?<span className="text-white material-icons" id="skill_exp" onClick={expand}>expand_less</span>:null}</div>
                            <div id="skill_detail">
                            {data && data.user_skills && data.user_skills.length >= 0 ?
                                <div className="bg-white d-flex flex-row flex-wrap my-2 col-md-12 col-lg-12 col-sm-12">
                                    {data.user_skills.map(sk=>{
                                        return <SkillPill removable={true} id={sk.id} name={sk.name} selected={true} key={sk.id} onRemove={(id)=>removeSkill(id)} onSelect={()=>{}}/>
                                    })}
                                </div>:<>
                                <h3>No skills</h3><p>Please select below applicable skills</p></>
                            }
                        
                        {skills.length >= 0 ?
                                <div  className="bg-white d-flex flex-row flex-wrap my-2 col-md-12 col-lg-12 col-sm-12">
                                    {skills.map(sk=>{
                                        return <SkillPill removable={false} id={sk.id} name={sk.name} selected={false} key={sk.id} onSelect={(id)=>selectSkill(id)}/>
                                    })}
                                </div>:
                                null
                            }
                        </div>
                           </div>
                            <div className="border border-secondary bg-light py-3 pt-0 my-1 col-md-8 col-lg-8 col-sm-10 offset-md-2 offset-lg-2 offset-sm-1 row">
                                
                                    <div className="text-white text-medium bg-dark my-2 mt-0 d-flex flex-row align-items-center justify-content-between col-md-12 col-lg-12"><span>Work Experience</span>{data && data.work && data.work.length > 0 ?<span className="text-white material-icons" id="work_exp" onClick={expand}>expand_less</span>:null}</div>
                                    {data && data.work && data.work.length > 0?
                                    <div id="work_detail">
                                        {data.work.map(d=>{
                                            return(<div key={d.id} className="list-item" onClick={()=>editWork(d)}>
                                                <span className="material-icons text-success">work</span>&nbsp;
                                                <b>{d.title+" at "+d.institution+": "}</b>
                                                <span>&nbsp;{d.year_start+ " - "+(d.year_end == -1 ? "Present" : d.year_end)}</span>
                                            </div>)
                                        })}
                                    </div>:<>
                                <h3>No work records</h3><p>Please click the add work link</p></>}
                                    {showWorkForm ? <WorkForm data={work} onCancel={(tag)=>handleCancelForm(tag)} onSave={(d)=>updateWorkData(d)}/>:null}
                                    <span className="text-primary text-right btn" onClick={showHideWorkForm}>{showWorkForm ? "HIDE":"ADD"}</span>  
                                
                            </div>
                          <div className="border border-secondary bg-light py-3 pt-0 my-1 col-md-8 col-lg-8 col-sm-10 offset-md-2 offset-lg-2 offset-sm-1 row">
                            <div className="text-white text-medium bg-dark my-2 mt-0 d-flex flex-row align-items-center justify-content-between col-md-12 col-lg-12"><span>Education Background</span><span className="text-white material-icons" id="education_exp" onClick={expand}>expand_less</span></div>
                            {data && data.education && data.education.length > 0?
                                <div id="education_detail">
                                    {data.education.map(d=>{
                                        return(<div key={d.id} className="list-item" onClick={()=>editEd(d)}>
                                            <span className="material-icons text-success">school</span>
                                            <b>{d.year+ " "+d.institution+"("+d.country+"): "}</b>
                                            <span>&nbsp;{d.title+", "+commons.getAwards()[d.award-1]}</span>
                                        </div>)
                                    })}
                                </div>:<>
                                <h3>No Education</h3><p>Please click the add education link</p></>}
                            {showEdForm ? <EducationForm data={ed} onCancel={(tag)=>handleCancelForm(tag)} onSave={(d)=>updateEdData(d)}/>:null}
                            <span className="text-primary text-right btn" onClick={showHideEdForm}>{showEdForm ? "HIDE":"ADD"}</span>
                          </div>
                          
                        </main>
                    </div>
                </div>
            </div>
        )
    }
    else{
        return <LoginForm target="/profile" onAuth={(user=>handleAuth(user))} />
    }
}
export default Profile;