import React, { useEffect,useState } from "react";
// import { useState } from "react/cjs/react.development";
import commons from "../utils/commons";

const WorkForm=(props)=>{
    var storage = window.localStorage;
    var storedData = JSON.parse(storage.getItem("data"));
    var loginState = JSON.parse(window.sessionStorage.getItem("loginState"));
    const regionData = commons.getRegionalData();
    const [feedback,setFeedback] = useState("");
    const [hasError,setHasError] = useState(false);
    const [isEdit,setIsEdit] = useState(props.data != null);
    const [data,setData] = useState(props.data);
    const currentYear = new Date().getFullYear();
    // setData(props.data);
    useEffect(()=>{
        setData(props.data);
        console.log("wd200: ",data);
    },[data])
    console.log("wd100: ",data);
    const handleSubmit=(e)=>{
        e.preventDefault();
        var form = e.target;
        var wd = {};
        if(isEdit) wd.id = data.id;
        wd.title = form.title.value;
        wd.year_start = form.year_start.value;
        wd.year_end = form.year_end.value;
        wd.institution = form.institution.value;
        wd.role_description = form.role_description.value;
        wd.user_id = loginState.user_id;
        console.log("wd1: ",wd);
       
        saveWork(wd).then(result=>{
        console.log("result: ",result);
        setHasError(!result.status);
        setFeedback(result.message);
        if(result.status){
            storedData.profile.work = result.experience;
            storage.setItem("data",JSON.stringify(storedData));
            storedData = JSON.parse(storage.getItem("data"));
            props.onSave(result.experience);
            handleCancelForm("work");
        }
        
       }).catch(e=>{
            setHasError(true);
            setFeedback(e)
       });
    }
    const saveWork= (work=>{
        
        return new Promise((resolve,reject)=>{
            var options = {
                body:JSON.stringify({auth:loginState,data:work}),
                method:"POST",headers:{'Content-type':'application/json'}
            }
            let url = commons.getURL("experience")+"/"+(!isEdit ? "create.php":"update.php");
            fetch(url,options)
                    .then(res=>res.json())
                    .then(result=>{
                        resolve(result);
                    })
                    .catch(e=>{
                        console.log("err: ",e);
                        reject("Oops! Something went wrong!");
                    })
                    
            })
        })
       
    const handleCancelForm=()=>{
        props.onCancel("work")
    }
    const deleteRecord=()=>{
        if(window.confirm("Are you sure you want delete this record?")){
          var options = {
              body:JSON.stringify({auth:loginState,data:data}),
              method:"POST",headers:{'Content-type':'application/json'}
          }
          let url = commons.getURL("experience")+"/delete.php";
          fetch(url,options)
                  .then(res=>res.json())    
                  .then(result=>{
                      setHasError(!result.status);
                      setFeedback(result.message);
                      if(result.status){
                          storedData.profile.work = result.experience;
                          storage.setItem("data",JSON.stringify(storedData));
                          storedData = JSON.parse(storage.getItem("data"));
                          props.onSave(storedData.profile.work);
                          handleCancelForm();
                      }
                  })
                  .catch(e=>{
                      console.log("err: ",e);
                      setHasError(true);
                      setFeedback("Oops! Something went wrong!");
                  })
                  
          
        }
    }
    return(<form encType="multipart/form-data" onSubmit={handleSubmit} className="offset-md-1 border border-secondary bg-light col-md-10 col-lg-10 col-sm-12">
    <div className="text-bold py-2 text-right text-success">Add Work</div>
    {feedback.length > 0 ? <div className={"my-2 "+(hasError ? "alert-danger" : "alert-success")}>{feedback}</div>:null}
    
    <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
        <label htmlFor="position">Title/Position</label>
        <input className="form-control" id="title" name="title" required placeholder="Program" defaultValue={isEdit ? data.title:""}/>
       
    </div>
    <div className="my-2 d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
        <label htmlFor="institution">Organisation/Company</label>
        <input type="text" className="form-control text-left" id="institution" name="institution"defaultValue={isEdit ? data.institution:""}/>
    </div>
    <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
        <label htmlFor="year_start">Year Start</label>
        <select className="form-control" id="year_start" name="year_start" defaultValue={isEdit ? data.year_start:currentYear}>
        {commons.generateYears(1960,currentYear).map((y)=>{
                return (<option key={y}>{y}</option>)
            })}
        </select>
    </div>
    <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
        <label htmlFor="year_end">Year End</label>
        <select className="form-control" id="year_end" name="year_end" defaultValue={isEdit ? data.year:currentYear}>
            <option value="-1">Still Working</option>
            {commons.generateYears(1960,currentYear).map((y)=>{
                return (<option key={y}>{y}</option>)
            })}
        </select>
    </div>
    <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-12 col-lg-12">
        <label htmlFor="role_description">Role Description</label>
        <textarea className="form-control" id="role_description" name="role_description" defaultValue={isEdit ? data.role_description:""} ></textarea>
    </div>
   
  
   
   
    <div className="my-2 form-group d-flex align-items-center justify-content-between flex-row flex-wrap">
        <input className="col-md-3 col-lg-2 col-sm-12 btn bg-primary text-white" type="submit" value="SAVE"/>
        {isEdit ? <span className="text-danger col-md-3 col-lg-2 col-sm-12 btn" onClick={deleteRecord}>DELETE</span>:null}
        <input onClick={()=>handleCancelForm()} className="text-primary col-md-3 col-lg-2 col-sm-12 btn" type="button" value="CANCEL"/>
    </div>
</form>
)
}
export default WorkForm;