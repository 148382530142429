import React, { useEffect,useState } from "react";
// import { useState } from "react/cjs/react.development";
import commons from "../utils/commons";

const UserForm=(props)=>{
    var storage = window.localStorage;
    var storedData = JSON.parse(storage.getItem("data"));
    const regionData = commons.getRegionalData();
    const [zone,setZone] = useState(0);
    const [feedback,setFeedback] = useState("");
    const [hasError,setHasError] = useState(false);
    const [validPhone,setValidPhone] = useState();
    
    const handleSubmit=(e)=>{
        e.preventDefault();
        var inputs = Array.from(e.target.elements);
        var fd = {};
        fd.id = storedData.users.length;
        fd.ngazi = inputs[0].value;
        fd.kanda = inputs[1].value;
        fd.mkoa = inputs[2].value;
        fd.wilaya = inputs[3].value;
        fd.jina = inputs[4].value.trim();
        fd.email = inputs[5].value.trim();
        fd.simu = inputs[6].value.trim();
        fd.password = inputs[7].value.trim();
       
        console.log("fd1: ",fd);
        // storedData.users.push(fd);
        // userStore.push(fd);
        // storage.setItem("userStore",JSON.stringify(userStore));
        // storage.setItem("data",JSON.stringify(storedData));
        // storedData = JSON.parse(storage.getItem("data"));
        // props.onSave(storedData.users);
        // handleCancelForm("users");

       saveUser(fd).then(result=>{
        console.log("result: ",result);
        setHasError(!result.status);
        setFeedback(result.message);
        storedData.users.push(fd);
        storage.setItem("data",JSON.stringify(storedData));
        storedData = JSON.parse(storage.getItem("data"));
        props.onSave(storedData.users);
        handleCancelForm("users")
       }).catch(e=>{
            setHasError(true);
            setFeedback(e)
       });
    }
    const saveUser= (user=>{
        return new Promise((resolve,reject)=>{
            var options = {
                body:JSON.stringify(user),
                method:"POST",headers:{'Content-type':'application/json'}
            }
            fetch(commons.getURL("users")+"/create.php",options)
                    .then(res=>res.json())
                    .then(result=>{
                        resolve(result);
                    })
                    .catch(e=>{
                        console.log("err: ",e);
                        reject("Pole, kuna tatizo limetokea!");
                    })
                    
            })
        })
       
    const handleCancelForm=(tag)=>{
        props.onCancel(tag)
    }
    const handleZoneChange = (e)=>{

        let zId = parseInt(e.target.value);
        let zone = regionData[zId];
        const regionEl = document.getElementById("user-region");
        Array.from(regionEl.children).forEach((c,i)=>{if(i>0)regionEl.removeChild(c);});
        zone.regions.forEach(r=>{
            let reg = r.name;
            regionEl.options.add(new Option(reg,r.key));
        })
        setZone(zId)
    }
    const populateWilaya = (region)=>{
        const distEl = document.getElementById("user-district");
       Array.from(distEl.children).forEach((c,i)=>{if(i>0)distEl.removeChild(c);});
       if(region != 0){
        region = (region.includes(" ")) ? region.toLowerCase().replaceAll(" ","_"):region.toLowerCase();
        console.log("region: "+region,regionData[zone].regions.filter(r=>r.key == region));//.filter(r=>r.key == region));
        let districts = regionData[zone].regions.filter(r=>r.key == region)[0].districts;
        districts.forEach(d=>{
            distEl.options.add(new Option(d.name));
        })
       }
       
    }
    const handleRegionChange=(e)=>{
        let region = e.target.value.toLowerCase();
        const distEl = document.getElementById("user-district");
        Array.from(distEl.children).forEach(c=>distEl.removeChild(c));
        if(region != 0) populateWilaya(region);
    }
    const handlePhoneChange = (e)=>{
        let phone = e.target.value;
        if(phone.length > 0){
            let valid = commons.validatePhone(phone);
            setValidPhone(valid);
        }
        
    }
    useEffect(()=>{
        let phone = document.getElementById("user-phone").value;
        if(phone.length > 0){
            let valid = commons.validatePhone(phone);
            setValidPhone(valid);
        }
    },[])
    return(<form encType="multipart/form-data" onSubmit={handleSubmit} className="border border-secondary bg-light p-3 my-5 col-md-8 col-lg-8 col-sm-10 offset-md-2 offset-lg-2 offset-sm-1 row">
    <div className="text-medium py-2">Usajili wa Mtumiaji</div>
    {feedback.length > 0 ? <div className={"my-2 "+(hasError ? "alert-danger" : "alert-success")}>{feedback}</div>:null}
    
    <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
        <label htmlFor="user-position">Ngazi ya Utawala</label>
        <select className="form-control" id="user-position" name="user-position">
            {storedData.roles.map(r=>{
                return(<option key={r.role_id} value={r.level}>{r.role_name}</option>)
            })}
        </select>
    </div>
    <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
        <label htmlFor="user-zone">Kanda</label>
        <select className="form-control" id="user-zone" name="user-zone" onChange={handleZoneChange}>
            <option value="0">Bara</option>
            <option value="1">Zanzibar</option>
        </select>
    </div>
    <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
        <label htmlFor="user-region">Mkoa</label>
        <select className="form-control" id="user-region" name="user-region" onChange={handleRegionChange}>
            <option value="0">--chagua mkoa--</option>
        {regionData[0].regions.map(city=>{
                return(<option key={city.key} value={city.key}>{city.name}</option>)
            })}
        </select>
    </div>
   
    <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
        <label htmlFor="user-district">Wilaya *</label>
        <select required={true} className="form-control" id="user-district" name="user-district">
        <option value="0">--chagua wilaya--</option>
        </select>
    </div>
    <div className="my-2 d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
        <label htmlFor="user-name">Majina Kamili *</label>
        <input required={true} className="form-control" type="text" id="user-name" name="user-name" placeholder="Majina ya mtumiaji"/>
    </div>
   
    <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
        <label htmlFor="user-email">Barua-pepe *</label>
        <input className="form-control" required={true} type="email" id="user-email" name="user-email" placeholder="Barua pepe"/>
         </div>
    
   
    <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
        <label htmlFor="user-phone">Namba ya Simu</label>
        <input onInput={handlePhoneChange} className={"form-control "+validPhone ? "":"border border-danger"} type="number" id="user-phone" name="user-phone" placeholder="Na. ya simu ya mtumiaji"/>
        {validPhone ? null :<small className="text-danger">Namba ya simu si sahihi</small>}
   </div>
    <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
        <label htmlFor="user-password">Neno la siri *</label>
        <input className="form-control" required={true} type="password" id="user-password" name="user-password" placeholder="Neno la siri"/>
    </div>
    
    <div className="my-2 form-group d-flex align-items-center justify-content-between flex-row">
        <input className="col-md-3 col-lg-2 col-sm-6 btn bg-primary text-white" type="submit" value="HIFADHI"/>
        <input onClick={()=>handleCancelForm("users")} className="text-primary col-md-3 col-lg-2 col-sm-6 btn" type="button" value="GHAIRI"/>
    </div>
</form>
)
}
export default UserForm;