import React, { useEffect,useState } from "react";
import logo from "../logo.png";
import Spinner from "./spinner";
import validator from "validator";
import Commons from "../utils/commons";
const LoginForm =(props)=>{

    // var [user,setUser] = useState();
    const [isLoading,setIsLoading] = useState(false);
    const [email,setEmail] = useState('');
    const [password,setPassword] = useState('');
    const [hasError,setHasError] = useState(false);
    const [errorMsg,setErrorMsg] = useState('')
    var storage = window.localStorage;
    var session = window.sessionStorage;
    var loginState = JSON.parse(session.getItem("loginState"));
    // var userStore = storage.getItem("userStore") ? JSON.parse(storage.getItem("userStore")):[];
    
    const signIn= (email,password)=>{
        return new Promise((resolve,reject)=>{
            var url = Commons.getURL("login")+"/index.php";
            var options = {
                body:JSON.stringify({email:email,password:password}),
                method:"POST",
                headers:{'Content-type':'application/json'}
            }
            fetch(url,options)
            .then(res=>{
                console.log("respon: ",res);
                return res.json();
            })
            .then(result=>{
                console.log("res: ",result);
                setIsLoading(false)
                resolve(result);
            })
            .catch(e=>{
                console.log("err: ",e);
                setIsLoading(false)
                reject("Oops! Something went wrong");
                
            })

            // if(email == "landry@gmail.com" && password=="password"){
            //     resolve({status:true,message:'success',user:{email:email,jina:"System Admin",ngazi:1}});
            // }
            // else reject("Pole, jaribu tena au jisajili");
            setIsLoading(false);
        });
        
    }
    const handleSubmit = (e)=>{
        e.preventDefault();
        let email = document.getElementById("email").value.trim().toLowerCase();
        let password = document.getElementById("password").value.trim();
            setIsLoading(true);

           signIn(email,password)
           .then(result=>{
               console.log("resu: ",result)
                setHasError(!result.status);
                setErrorMsg(result.message);
                if(result.status){
                    storeUser(result.user);
                    let dataObject = {
                        jobs:[],users:[],settings:[],applications:[],profile:{},skills:[]
                    }
                    storage.setItem("data",JSON.stringify(dataObject));
                    window.location.pathname = (props.target) ? props.target : "/dashboard";
                }

           }).catch(e=>{
            console.log("error: ",e);
            setHasError(true);
            setErrorMsg(e.toString());
           });
        }
        const loadData=()=>{
            return new Promise((resolve,reject)=>{
               
                if(loginState){
                    let user_url = Commons.getURL("data")+"/index.php";
                    var options={
                        method:"POST",
                        body:JSON.stringify({auth:loginState}),
                        headers:{'Content-type':'application/json'}
                    }
                    fetch(user_url,options)
                    .then(res=>res.json())
                    .then(result=>{
                        resolve(result);
                    })
                    .catch(e=>{
                        console.log(e);
                        reject("Oops! Something went wrong");
                    })
                    
                }
                
            })
        }
    const storeUser = (u)=>{
        session.setItem("loginState",JSON.stringify(u));
    }
    const handleChange = (e)=>{
        if(validator.isEmail(e.target.value)){
           setEmail(e.target.value.trim());
           setHasError(false);
           setErrorMsg("");

        }
        else{
            setHasError(true);
            setErrorMsg("Invalid email address")
        }
    }
    const handlePasswordChange = (e)=>{
        setPassword(e.target.value.trim());
    }
   
    return(
        <main className="my-4 py-4 text-center">
            <img src={logo} className="front_logo mx-auto"/>
            <form className="mx-auto col-md-8 col-lg-8 col-sm-10 offset-2 offset-sm-1 p-2" onSubmit={handleSubmit}>
                <div className="primary-text my-3 col-md-8 col-lg-8 col-sm-10 offset-lg-2 offset-md-2 offset-sm-1 text-center mx-auto">Pannecia HR</div>
                {hasError ? <div className="form-group my-2 col-md-4 col-lg-4 col-sm-10 offset-lg-4 offset-md-4 offset-sm-1 alert-danger p-1 ">{errorMsg}</div>:null}
                <div className="form-group my-2 col-md-4 col-lg-4 col-sm-10 offset-lg-4 offset-md-4 offset-sm-1">
                    {/* <label for="email" className="text-left">Username/E-mail address</label> */}
                    <input type="email" id="email" name="email" placeholder="Email address" className="form-control" required={true} onChange={handleChange}/>
                </div>
                <div className="form-group my-2 col-md-4 col-lg-4 col-sm-10 offset-lg-4 offset-md-4 offset-sm-1">
                    {/* <label for="password" className="text-left">Password</label> */}
                    <input type="password" id="password" name="password" placeholder="Password" className="form-control" onChange={handlePasswordChange}/>
                </div>
                <div className="form-group my-2 col-md-4 col-lg-4 col-sm-10 offset-lg-4 offset-md-4 offset-sm-1">
                    {isLoading ? <Spinner isShowing={true} size="spinner"/> : <input type="submit" value="LOGIN" className="btn-success form-control" />}
                </div>
                <div className="form-group my-2 col-md-4 col-lg-4 col-sm-10 offset-lg-4 offset-md-4 offset-sm-1 text-left">
                    <a href="/password_reset">I forgot my password</a>    
                </div>
                <div className="form-group my-2 col-md-4 col-lg-4 col-sm-10 offset-lg-4 offset-md-4 offset-sm-1 text-left">
                    <a href="/signup">Create Account</a>    
                </div>
            </form>

        </main>
    )
   
}
export default LoginForm;
