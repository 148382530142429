import React, { useEffect,useState } from "react";
// import { useState } from "react/cjs/react.development";
import commons from "../utils/commons";
import LoginForm from "./login_form";
import Header from "./header";
import SideBar from "./side_bar";
import SkillForm from "./skill_form";
const SettingsForm=(props)=>{
    var storage = window.localStorage;
    var storedData = JSON.parse(storage.getItem("data"));
    var loginState = JSON.parse(window.sessionStorage.getItem("loginState"));
    const session = window.sessionStorage;
    
    const regionData = commons.getRegionalData();
    const [feedback,setFeedback] = useState("");
    const [hasError,setHasError] = useState(false);
    const [isEdit,setIsEdit] = useState(props.data != null);
    const [data,setData] = useState(props.data);
    const currentYear = new Date().getFullYear();
    // setData(props.data);
    useEffect(()=>{
        setData(props.data);
        console.log("wd200: ",data);
    },[data]);
    
    const handleSubmit=(e)=>{
        e.preventDefault();
        var form = e.target;
        var wd = {};
        if(isEdit) wd.id = data.id;
        wd.title = form.title.value;
        wd.year_start = form.year_start.value;
        wd.year_end = form.year_end.value;
        wd.institution = form.institution.value;
        wd.role_description = form.role_description.value;
        wd.user_id = loginState.user_id;
        saveWork(wd).then(result=>{
        console.log("result: ",result);
        setHasError(!result.status);
        setFeedback(result.message);
        if(result.status){
            storedData.profile.work = result.experience;
            storage.setItem("data",JSON.stringify(storedData));
            storedData = JSON.parse(storage.getItem("data"));
            props.onSave(result.experience);
            handleCancelForm("work");
        }
        
       }).catch(e=>{
            setHasError(true);
            setFeedback(e)
       });
    }
    const saveWork= (work=>{
        
        return new Promise((resolve,reject)=>{
            var options = {
                body:JSON.stringify({auth:loginState,data:work}),
                method:"POST",headers:{'Content-type':'application/json'}
            }
            let url = commons.getURL("experience")+"/"+(!isEdit ? "create.php":"update.php");
            fetch(url,options)
                    .then(res=>res.json())
                    .then(result=>{
                        resolve(result);
                    })
                    .catch(e=>{
                        console.log("err: ",e);
                        reject("Oops! Something went wrong!");
                    })
                    
            })
        })
        const loadDataFromStorage = (storedData,callback)=>{
            if(storedData){
                var users = (storedData.users) ? storedData.users:[];
              //  setUsers(users);
                callback();
            }
        }   
    const handleCancelForm=()=>{
        props.onCancel("work")
    }
    const deleteRecord=()=>{
        if(window.confirm("Are you sure you want to delete this record?")){
          var options = {
              body:JSON.stringify({auth:loginState,data:data}),
              method:"POST",headers:{'Content-type':'application/json'}
          }
          let url = commons.getURL("experience")+"/delete.php";
          fetch(url,options)
                  .then(res=>res.json())    
                  .then(result=>{
                      setHasError(!result.status);
                      setFeedback(result.message);
                      if(result.status){
                          storedData.profile.work = result.experience;
                          storage.setItem("data",JSON.stringify(storedData));
                          storedData = JSON.parse(storage.getItem("data"));
                          props.onSave(storedData.profile.work);
                          handleCancelForm();
                      }
                  })
                  .catch(e=>{
                      console.log("err: ",e);
                      setHasError(true);
                      setFeedback("Oops! Something went wrong!");
                  })
                  
          
        }
    }
    const handleAuth=(u)=>{
        session.setItem("loginState",JSON.stringify(u));
        loginState = u;
        storedData = JSON.parse(storage.getItem("data"));
        loadDataFromStorage(storedData)
    }
    if(loginState){
        return(
            <div>
           <Header/>
            <div className="container-fluid">
                <div className="row">
               <SideBar selected="settings"/>
                 <main className="col-md-10 ms-sm-auto col-lg-10 px-md-4 mx-auto my-4">
                    <SkillForm data={null}/>
                    </main>
                </div></div></div>
)
    }
    else{
        return <LoginForm target="/settings" onAuth={(user=>handleAuth(user))} />
    }
}
export default SettingsForm;