import React, { useEffect,useState } from "react";
import logo from "../logo.png";
import Spinner from "./spinner";
import validator from "validator";
import commons from "../utils/commons";
const SignupForm =(props)=>{

    // var [user,setUser] = useState();
    const [isLoading,setIsLoading] = useState(false);
    const [email,setEmail] = useState('');
    const [passwordMatch,setPasswordMatch] = useState();
    const [hasError,setHasError] = useState(false);
    const [errorMsg,setErrorMsg] = useState('');
    const [validPhone,setValidPhone] = useState();
    const [showPwd,setShowPwd] = useState(false);
    const [validPwd,setValidPwd] = useState();
    var storage = window.localStorage;
    var session = window.sessionStorage;
    var storedData = JSON.parse(storage.getItem("data"));
    // var userStore = storage.getItem("userStore") ? JSON.parse(storage.getItem("userStore")):[];
    
    const signUp= (fname,lname,phone,email,password)=>{
        return new Promise((resolve,reject)=>{
            var url = commons.getURL("login")+"/index.php";
            var options = {
                body:JSON.stringify({email:email,password:password,fname:fname,lname:lname,phone:phone,signup:true}),
                method:"POST",
                headers:{'Content-type':'application/json'}
            }
            fetch(url,options)
            .then(res=>res.json())
            .then(result=>{
                console.log("res: ",result);
                setIsLoading(false)
                resolve(result);
            })
            .catch(e=>{
                console.log("err: ",e);
                setIsLoading(false)
                reject("Oops! Something went wrong, please try again later");
                
            })

            // if(email == "landry@gmail.com" && password=="password"){
            //     resolve({status:true,message:'success',user:{email:email,jina:"System Admin",ngazi:1}});
            // }
            // else reject("Pole, jaribu tena au jisajili");
            setIsLoading(false);
        });
        
    }
    const handlePWordVisibility=(e)=>{
        var pwrd = document.getElementById("password");
        var cpwrd = document.getElementById("cpassword");
        if(showPwd){
            pwrd.type = "password";
            cpwrd.type = "password";
            e.target.textContent = "visibility";
        }
        else{
            pwrd.type = "text";
            cpwrd.type = "text";
            e.target.textContent = "visibility_off";
        }
        setShowPwd(!showPwd);
    }
    const handleSubmit = (e)=>{
        e.preventDefault();
        let email = document.getElementById("email").value.trim().toLowerCase();
        let password = document.getElementById("password").value.trim();
            setIsLoading(true);
        let fname = document.getElementById("fname").value.trim();
        let lname = document.getElementById("lname").value.trim();
        let phone = document.getElementById("phone").value.trim();

           signUp(fname,lname,phone,email,password)
           .then(result=>{
               console.log("resu: ",result)
                setHasError(!result.status);
                setErrorMsg(result.message);
                if(result.status){
                    if(result.status){
                        storeUser(result.user);
                        let dataObject = {
                            jobs:[],users:[],settings:[],applications:[]
                        }
                        storage.setItem("data",JSON.stringify(dataObject));
                        window.location.pathname = (props.target) ? props.target : "/dashboard";
                    }
                };

           }).catch(e=>{
            console.log("error: ",e);
            setHasError(true);
            setErrorMsg(e.toString());
           });
        }
    const storeUser = (u)=>{
        session.setItem("loginState",JSON.stringify(u));
    }
    const handleChange = (e)=>{
        if(validator.isEmail(e.target.value)){
           setEmail(e.target.value.trim());
           setHasError(false);
           setErrorMsg("");

        }
        else{
            setHasError(true);
            setErrorMsg("Invalid email address")
        }
    }
    const handlePasswordChange = (e)=>{
        if(e.target.value.length > 0){
            let valid = commons.validatePassword(e.target.value.trim());
            setValidPwd(valid);
            if(!valid){
                setErrorMsg("Invalid password")
            }
            else{
                setErrorMsg("");
            }
        }
    }
    const handleCPasswordChange=(e)=>{
        let cpassword = e.target.value.trim();
        let password = document.getElementById("password").value.trim();
        if(cpassword.length > 0){
            let match = password === cpassword;
            setPasswordMatch(match);
            if(!match){
                setErrorMsg("Passwords do not match")
            }
            else{
                setErrorMsg("");
            }
        }
    }
    const loadData=()=>{
    return new Promise((resolve,reject)=>{
        let loginState = JSON.parse(session.getItem("loginState"));
        if(loginState){
            let user_url = commons.getURL("data")+"/index.php";
            var options={
                method:"POST",
                body:JSON.stringify({auth:loginState}),
                headers:{'Content-type':'application/json'}
            }
            fetch(user_url,options)
            .then(res=>res.json())
            .then(result=>{
                resolve(result);
            })
            .catch(e=>{
                console.log(e);
                reject("Pole... kuna tatizo limetokea");
            })
            .finally(()=>{
                setIsLoading(false);
            })
        }
        
    })
    }
    const handlePhoneChange = (e)=>{
        let phone = e.target.value;
        if(phone.length > 0){
            let valid = commons.validatePhone(phone);
            setValidPhone(valid);
            if(!valid){
                setErrorMsg("Invalid phone number")
            }
            else{
                setErrorMsg("");
            }
        }
        else{
            setValidPhone(true);
            setErrorMsg("");
        }
        
    }
    return(
        <main className="my-4 py-4 text-center">
            <img src={logo} className="front_logo mx-auto"/>
            <form className="mx-auto col-md-8 col-lg-8 col-sm-10 offset-2 offset-sm-1 p-2" onSubmit={handleSubmit}>
                <div className="primary-text my-3 col-md-8 col-lg-8 col-sm-10 offset-lg-2 offset-md-2 offset-sm-1 text-center mx-auto">Sign Up</div>
                {hasError ? <div className="form-group my-2 col-md-4 col-lg-4 col-sm-10 offset-lg-4 offset-md-4 offset-sm-1 alert-danger p-1 ">{errorMsg}</div>:null}
                <div className="form-group my-2 col-md-4 col-lg-4 col-sm-10 offset-lg-4 offset-md-4 offset-sm-1">
                    {/* <label for="email" className="text-left">Username/E-mail address</label> */}
                    <input type="text" id="fname" name="fname" placeholder="First Name" className="form-control" required={true}/>
                </div>
                <div className="form-group my-2 col-md-4 col-lg-4 col-sm-10 offset-lg-4 offset-md-4 offset-sm-1">
                    {/* <label for="email" className="text-left">Username/E-mail address</label> */}
                    <input type="text" id="lname" name="lname" placeholder="Last Name" className="form-control" required={true}/>
                </div>
                <div className="form-group my-2 col-md-4 col-lg-4 col-sm-10 offset-lg-4 offset-md-4 offset-sm-1">
                    {/* <label for="email" className="text-left">Username/E-mail address</label> */}
                    <input type="number" id="phone" name="phone" placeholder="Phone Number" className="form-control" required={true} onInput={handlePhoneChange}/>
                    {validPhone ===false ?<small className="text-danger text-left">{errorMsg}</small>:null}
                </div>
                <div className="form-group my-2 col-md-4 col-lg-4 col-sm-10 offset-lg-4 offset-md-4 offset-sm-1">
                    {/* <label for="email" className="text-left">Username/E-mail address</label> */}
                    <input type="email" id="email" name="email" placeholder="Email address" className="form-control" required={true} onChange={handleChange}/>
                </div>
                <div className="form-group my-2 col-md-4 col-lg-4 col-sm-10 offset-lg-4 offset-md-4 offset-sm-1 ">
                    <div className="form-control d-flex justify-content-between">
                    <input type="password" id="password" name="password" placeholder="Password" className="no-outline" onChange={handlePasswordChange}/>
                    <span id="show_password" className="material-icons" onClick={handlePWordVisibility}>visibility</span></div>
                    {validPwd ===false?<small className="text-danger text-left">{errorMsg}</small>:null}
                
                </div>
                <div className="form-group my-2 col-md-4 col-lg-4 col-sm-10 offset-lg-4 offset-md-4 offset-sm-1">
                    {/* <label for="password" className="text-left">Password</label> */}
                    <input type="password" id="cpassword" name="cpassword" placeholder="Confirm" className="form-control" onChange={handleCPasswordChange}/>
                    {passwordMatch ===false ?<small className="text-danger text-left">{errorMsg}</small>:null}
                </div>
                <div className="form-group my-2 col-md-4 col-lg-4 col-sm-10 offset-lg-4 offset-md-4 offset-sm-1">
                    {isLoading ? <Spinner isShowing={true} size="spinner"/> : <input type="submit" value="SIGN UP" className="btn-success form-control" />}
                </div>
                <div className="form-group my-2 col-md-4 col-lg-4 col-sm-10 offset-lg-4 offset-md-4 offset-sm-1 text-left">
                    <a href="/login">Already signed up</a>    
                </div>
            </form>

        </main>
    )
   
}
export default SignupForm;
