import React, { useEffect, useState } from "react";

import SideBar from "./side_bar";
import Header from "./header";
const NotFound=(props)=>{
   
        return(
            <div>
           <Header/>
            <div className="container-fluid">
                <div className="row">
               <SideBar selected="candidat"/>
                 <main className="col-md-10 ms-sm-auto col-lg-10 px-md-4 mx-auto my-4">
                    <h1>404: Page Not Found</h1>   
                </main>     
                </div>
            </div>
        </div>
        )
    }
   
export default NotFound;