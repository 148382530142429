import React, { useEffect,useState } from "react";
// import { useState } from "react/cjs/react.development";
import commons from "../utils/commons";

const EducationForm=(props)=>{
    var storage = window.localStorage;
    var storedData = JSON.parse(storage.getItem("data"));
    var loginState = JSON.parse(window.sessionStorage.getItem("loginState"));
    const regionData = commons.getRegionalData();
    const [feedback,setFeedback] = useState("");
    const [hasError,setHasError] = useState(false);
    const [isEdit,setIsEdit] = useState(props.data != null);
    const [data,setData] = useState(props.data);
    const currentYear = new Date().getFullYear();
    // setData(props.data);
    useEffect(()=>{
        setData(props.data);
        console.log("fd200: ",data);
    },[data])
    console.log("fd100: ",data);
    const handleSubmit=(e)=>{
        e.preventDefault();
        var form = e.target;
        var fd = {};
        if(isEdit) fd.id = data.id;
        fd.title = form.title.value;
        fd.year = form.year.value;
        fd.country = form.country.value;
        fd.institution = form.institution.value;
        fd.award = form.award.value;
        fd.user_id = loginState.user_id;
        console.log("fd1: ",fd);
       
        saveEd(fd).then(result=>{
        console.log("result: ",result);
        setHasError(!result.status);
        setFeedback(result.message);
        if(result.status){
            storedData.profile.education = result.education;
            storage.setItem("data",JSON.stringify(storedData));
            storedData = JSON.parse(storage.getItem("data"));
            props.onSave(result.education);
            handleCancelForm();
        }
        
       }).catch(e=>{
            setHasError(true);
            setFeedback(e)
       });
    }
    const saveEd= (ed=>{
        
        return new Promise((resolve,reject)=>{
            var options = {
                body:JSON.stringify({auth:loginState,data:ed}),
                method:"POST",headers:{'Content-type':'application/json'}
            }
            let url = commons.getURL("education")+"/"+(!isEdit ? "create.php":"update.php");
            fetch(url,options)
                    .then(res=>res.json())
                    .then(result=>{
                        resolve(result);
                    })
                    .catch(e=>{
                        console.log("err: ",e);
                        reject("Oops! Something went wrong!");
                    })
                    
            })
        })
       
    const handleCancelForm=()=>{
        props.onCancel("education")
    }
  const deleteRecord=()=>{
      if(window.confirm("Are you sure you want delete this record?")){
        var options = {
            body:JSON.stringify({auth:loginState,data:data}),
            method:"POST",headers:{'Content-type':'application/json'}
        }
        let url = commons.getURL("education")+"/delete.php";
        fetch(url,options)
                .then(res=>res.json())
                .then(result=>{
                    setHasError(!result.status);
                    setFeedback(result.message);
                    if(result.status){
                        storedData.profile.education = result.education;
                        storage.setItem("data",JSON.stringify(storedData));
                        storedData = JSON.parse(storage.getItem("data"));
                        props.onSave(storedData.profile.education);
                        handleCancelForm();
                    }
                })
                .catch(e=>{
                    console.log("err: ",e);
                    setHasError(true);
                    setFeedback("Oops! Something went wrong!");
                })
                
        
      }
  }
    return(<form encType="multipart/form-data" onSubmit={handleSubmit} className="offset-md-1 border border-secondary bg-light col-md-10 col-lg-10 col-sm-12">
    <div className="text-bold py-2 text-right text-success">Add Education</div>
    {feedback.length > 0 ? <div className={"my-2 "+(hasError ? "alert-danger" : "alert-success")}>{feedback}</div>:null}
    
    <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
        <label htmlFor="position">Program Name</label>
        <input className="form-control" id="title" name="title" required placeholder="Program" defaultValue={isEdit ? data.title:""}/>
       
    </div>
    <div className="my-2 d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
        <label htmlFor="institution">Institution</label>
        <input type="text" className="form-control text-left" id="institution" name="institution"defaultValue={isEdit ? data.institution:""}/>
    </div>
    <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
        <label htmlFor="country">Country</label>
        <select className="form-control" id="country" name="country" defaultValue={isEdit ? data.country:"Tanzania"}>
        {commons.getCountries().map(c=>{
                return(<option key={c} value={c}>{c}</option>)
            })}
        </select>
    </div>
    <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
        <label htmlFor="year">Year Completed</label>
        <select className="form-control" id="year" name="year" defaultValue={isEdit ? data.year:currentYear}>
            {commons.generateYears(1960,currentYear).map((y)=>{
                return (<option key={y}>{y}</option>)
            })}
        </select>
    </div>
    <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
        <label htmlFor="location">Award</label>
        <select className="form-control" id="award" name="award"defaultValue={isEdit ? data.award -1:0} >
            {commons.getAwards().map((award,index)=>{
                return <option key={award} value={index +1}>{award}</option>
            })}
           
        </select>
    </div>
   
  
   
   
    <div className="my-2 form-group d-flex align-items-center justify-content-between flex-row flex-wrap">
        <input className="col-md-3 col-lg-2 col-sm-12 btn bg-primary text-white" type="submit" value="SAVE"/>
       {isEdit ? <span className="text-danger col-md-3 col-lg-2 col-sm-12 btn" onClick={deleteRecord}>DELETE</span>:null}
        <input onClick={()=>handleCancelForm()} className="text-primary col-md-3 col-lg-2 col-sm-12 btn" type="button" value="CANCEL"/>
    </div>
</form>
)
}
export default EducationForm;