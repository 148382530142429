import React, { useEffect, useState } from "react";
import JobForm from "./job_form";
import JobItem from "./job_list_item";
import ListHeadRow from "./header_row";
import Commons from "../utils/commons";
// import JobDetail from "./job_detail";
// import JobEditForm from "./job_edit_form";
import LoginForm from "./login_form";
import SideBar from "./side_bar";
import Header from "./header";
import JobDetail from "./job_detail";
import JobEdit from "./jobs_edit";
const Jobs=(props)=>{
    const storage = window.localStorage;
    const session = window.sessionStorage;
    var storedData = JSON.parse(storage.getItem("data"));
    var loginState = JSON.parse(session.getItem("loginState"));
    const [showJobsList,setShowJobsList] = useState(true);
    const [showJobForm,setShowJobForm] = useState(false);
    const [showJobEditForm,setShowJobEditForm] = useState(false);
    const [selectedJob,setSelectedJob] = useState();
    const [filteredJobs,setFilteredJobs] = useState([]);
    const [jobs,setJobs] = useState([]);
    let now = Date.now();
    useEffect(()=>{
        loadDataFromStorage(storedData,()=>{
        })
    },[])
    const handleSignout=(e)=>{
        e.preventDefault();
        window.sessionStorage.clear();
        window.localStorage.clear();
        window.location.href = "/";
    }
    
    const updateJobs=(data)=>{
        setJobs(data);
        setFilteredJobs(data);
    }
   
    const jobLabels = Commons.getJobListLabels().filter(f=>f.key == "date_created"||f.key == "title" || f.key == "location" || f.key == "experience"||f.key=="deadline");
    if(loginState.level != Commons.LEVEL_ADMIN) jobLabels.push({key:"applied",label:"Applied",type:"int"});
    else jobLabels.push({key:"status",label:"Status",type:"int"})
    const loadDataFromStorage = (storedData,callback)=>{
        if(storedData){
            var jobs = (storedData.jobs) ? storedData.jobs:[];
            jobs = jobs.map(j=>{
                let jb = j;
                jb.status = (j.deadline < now) ? "Expired":"Open";
                return jb;
            });
            setJobs(jobs);
            setFilteredJobs(jobs);
            callback();
        }
    }
    const handleAuth=(u)=>{
        session.setItem("loginState",JSON.stringify(u));
        loginState = u;
        storedData = JSON.parse(storage.getItem("data"));
        loadDataFromStorage(storedData)
    }
    const handleShowJobForm=(tag)=>{
        switch(tag){
            case "jobs":
                setShowJobsList(true);
                setShowJobForm(false);
                setShowJobEditForm(false);
                break;
            case "job_form":
                setShowJobsList(false);
                setShowJobForm(true);
                setShowJobEditForm(false);
                break;
            case "job_edit":
                setShowJobsList(false);
                setShowJobForm(false);
                setShowJobEditForm(true);
            break;
            case "job_detail":
                setShowJobsList(false);
                setShowJobForm(false);
                setShowJobEditForm(false);
                break;
            case null:
            default:
                setShowJobsList(true);
                setShowJobForm(false);
                setShowJobEditForm(false);
        }
       
    }
    const showDetails=(data)=>{
        setSelectedJob(data);
        setShowJobsList(false);
        setShowJobForm(false);
        setShowJobEditForm(false);
    }
    if(loginState){
        return(
            <div>
          <Header onSearch={(keyword)=>{
              var fJobs;
              if(keyword.length > 0)
              fJobs = jobs.filter(j=>j.title.toLowerCase().includes(keyword) || j.location.toLowerCase().includes(keyword));
            else fJobs = jobs;
            setFilteredJobs(fJobs);
          }}/>
            <div className="container-fluid">
                <div className="row">
                <SideBar selected="jobs"/>
                    <main className="col-md-10 ms-sm-auto col-lg-10 px-md-4 mx-auto my-4">
                    {showJobsList ? 
                     <>
                        <div className="col-lg-12 col-md-12 my-4 d-flex flex-row justify-content-start align-items-center row">
                            <h3 className="col-lg-6 col-md-6 col-sm-12 my-4 text-left">Jobs Openings</h3>
                          {loginState.level == Commons.LEVEL_ADMIN ?
                            <div className="col-lg-6 col-md-6 my-4 d-flex flex-row justify-content-end align-items-center"> 
                                <span onClick={()=>handleShowJobForm("job_form")} className="col-md-4 col-lg-4 btn mx-1 btn-secondary d-flex flex-row justify-content-center align-items-center"><span className="material-icons">add</span>&nbsp;Job</span>
                            </div>: null}
                        </div>
                            <ListHeadRow data={jobLabels}/>
                            {filteredJobs.length > 0 ?
                                filteredJobs.map((job,i)=>{
                                    return <JobItem job={job} key={job.id} position={i} onClick={(d)=>showDetails(d)}/>
                                })
                                :
                                <JobItem id={null} position={0}/>
                            }
    
                        
                        </>:(
                            showJobForm ? <JobForm onCancel={()=>handleShowJobForm("jobs")} onSave={(jobs)=>updateJobs(jobs)}/>
                            :(showJobEditForm ? <JobEdit onCancel={()=>handleShowJobForm("jobs")} onSave={(jobs)=>updateJobs(jobs)} job={selectedJob} />:<JobDetail job={selectedJob} onCancel={(tag)=>{handleShowJobForm(tag)}}/>)
                            )
                        
                        }
                     </main>
                </div>
            </div>
        </div>
        )
    }
    else return (<LoginForm target="/jobs" onAuth={(job=>handleAuth(job))} />)
    
}
export default Jobs;