import React, { useEffect, useState } from "react";
// import ApplicationForm from "./application_form";
import ApplicationItem from "./application_list_item";
import ListHeadRow from "./header_row";
import Commons from "../utils/commons";
import ApplicationDetail from "./application_detail";
import LoginForm from "./login_form";
import SideBar from "./side_bar";
import Header from "./header";
const Applications=(props)=>{
    const storage = window.localStorage;
    const session = window.sessionStorage;
    var storedData = JSON.parse(storage.getItem("data"));
    var loginState = JSON.parse(session.getItem("loginState"));
    const [showApplicationsList,setShowApplicationsList] = useState(true);
    const [showApplicationForm,setShowApplicationForm] = useState(false);
    const [showApplicationEditForm,setShowApplicationEditForm] = useState(false);
    const [selectedApplication,setSelectedApplication] = useState();
    // const [groups,setGroups] = useState(false);
    const [applications,setApplications] = useState([]);
    useEffect(()=>{
        loadDataFromStorage(storedData,()=>{
        })
    },[])
    
    
    const updateApplications=(data)=>{
        console.log("2u: ",data);
        var applications = data.map(m=>{
            let u=m;
            // u.ngazi = (parseInt(m.ngazi) === 0) ? "Mtumiaji wa Kawaida" : "Mtawala wa Mfumo";
            return u;
        });
        setApplications(applications);
    }
   
    const applicationLabels = Commons.getApplicationListLabels().filter(f=>f.key != "id" && f.key != "document_link" && f.key != "date_updated");
   
    const loadDataFromStorage = (storedData,callback)=>{
        if(storedData){
            var applications = (storedData.applications) ? storedData.applications:[];
            console.log("te: ",applications);
            setApplications(applications);
            callback();
        }
    }
    const handleAuth=(u)=>{
        session.setItem("loginState",JSON.stringify(u));
        loginState = u;
        storedData = JSON.parse(storage.getItem("data"));
        loadDataFromStorage(storedData)
    }
    const handleShowApplicationForm=(tag)=>{
        switch(tag){
            case "applications":
                setShowApplicationsList(true);
                setShowApplicationForm(false);
                setShowApplicationEditForm(false);
                setApplications(storedData.applications);
                break;
            case "application_form":
                setShowApplicationsList(false);
                setShowApplicationForm(true);
                setShowApplicationEditForm(false);
                break;
            case "application_edit":
                setShowApplicationsList(false);
                setShowApplicationForm(false);
                setShowApplicationEditForm(true);
            break;
            case "application_detail":
                setShowApplicationsList(false);
                setShowApplicationForm(false);
                setShowApplicationEditForm(false);
                break;
            case null:
            default:
                setShowApplicationsList(true);
                setShowApplicationForm(false);
                setShowApplicationEditForm(false);
        }
       
    }
    const showDetails=(data)=>{
        setSelectedApplication(data);
        setShowApplicationsList(false);
        setShowApplicationForm(false);
        setShowApplicationEditForm(false);
    }
    const handleSearch=(keyword)=>{
        
        if(showApplicationsList){
            console.log("searching...")
        }
    }
    if(loginState){
        return(
            <div>
            <Header onSearch={handleSearch}/>
            <div className="container-fluid">
                <div className="row">
                <SideBar selected="applications" />
                    <main className="col-md-10 ms-sm-auto col-lg-10 px-md-4 mx-auto my-4">
                    {showApplicationsList ? 
                     <>
                        <div className="col-lg-12 col-md-12 my-4 d-flex flex-row justify-content-end justify-content-sm-center align-items-center row">
                            <h3 className="col-lg-6 col-md-6 col-sm-12 my-4 text-left">Job Applications</h3>
                          
                            <div className="col-lg-6 col-md-6 my-4 d-flex flex-row justify-content-end align-items-center"> 
                                {/* <span onClick={()=>handleShowApplicationForm("application_form")} className="col-md-4 col-lg-4 btn mx-1 btn-secondary d-flex flex-row justify-content-center align-items-center"><span className="material-icons">add</span>&nbsp;Mtumiaji</span> */}
                            </div>
                        </div>
                            <ListHeadRow data={applicationLabels}/>
                            {console.log("te3: ",applications)}
                            {applications.length > 0 ?
                                applications.map((application,i)=>{
                                    return <ApplicationItem application={application} key={application.id} position={i} onClick={(d)=>showDetails(d)}/>
                                })
                                :
                                <ApplicationItem id={null} position={0}/>
                            }
    
                        
                        </>:(
                            <ApplicationDetail application={selectedApplication} onCancel={(tag)=>handleShowApplicationForm(tag)} />)
                        
                        }
                     </main>
                </div>
            </div>
        </div>
        )
    }
   else return (<LoginForm target="/applications" onAuth={(application=>handleAuth(application))} />)
    
}
export default Applications;