import React, { useEffect,useState } from "react";
import Spinner from "./spinner";
import Commons from "../utils/commons";

const JobEdit=(props)=>{
    var storage = window.localStorage;
    var storedData = JSON.parse(storage.getItem("data"));
    var loginState = JSON.parse(window.sessionStorage.getItem("loginState"));
    const regionData = Commons.getRegionalData();
    const [feedback,setFeedback] = useState("");
    const [hasError,setHasError] = useState(false);
    const [isLoading,setIsLoading] = useState(false);
    const [job,setJob] = useState(props.job);
    const [file,setFile] = useState();
    let x = new Date(parseInt(props.job.deadline));
   
    let deadline = Commons.getDays()[x.getDay()]+ " "+Commons.generateMonths()[x.getMonth()]+" "+x.getDate()+", "+x.getFullYear();
   
    const handleCancelForm=(tag)=>{
        props.onCancel(tag);
    }
    const handleChange=(e)=>{
        e.preventDefault();
        let myJob = job;
        let value = e.target.value.trim();
        switch(e.target.id){
            case "position":
                myJob.title = value;
                break;
            case "experience":
                // value = e.target.options[e.target.options.selectedIndex].value;
                myJob.experience = parseInt(value);
                break;
            case "location":
                myJob.location = value.replaceAll(" ","_").toLowerCase();
                break;
            case "duties":
                myJob.duties = value;
                break;
            case "description":
                myJob.description = value;
                break;
            case "qualification":
                myJob.qualification = value;
                break;
            case "address":
                myJob.address = value;
                break;
            case "salary":
                myJob.salary = value;
                break;
            case "institution":
                myJob.institution = value;
                break;
            case "deadline":
                myJob.deadline = Date.parse(value);
                break;
            case "employment":
                myJob.employment = value;
                break;
            case "attachment":
                let attachment = e.target.files[0];
                let reader = new FileReader();
                reader.addEventListener('load',()=>{
                    setFile(reader.result);
                    myJob.attachment = reader.result;
                },false);
                reader.readAsDataURL(attachment);
                break;
        }
        console.log("myJ: ",myJob);
        setJob(myJob);
    }
    const handleSubmit=(e)=>{
        e.preventDefault();
        update(job).then(result=>{
            console.log("attt: ",result);
            setHasError(!result.status);
            setFeedback(result.message);
            if(result.status){
                storedData.jobs = result.jobs;
                storage.setItem("data",JSON.stringify(storedData));
                storedData = JSON.parse(storage.getItem("data"));
                props.onSave(storedData.jobs);
                handleCancelForm("jobs");
            }
            else{
                document.getElementById("feedback").scrollIntoView();
            }
        })
        .catch(e=>{
            setHasError(true);
            setFeedback(e);
        })
    }
    const update=(job)=>{
        console.log("test...1: ",job);
        setIsLoading(true);
        return new Promise((resolve,reject)=>{
            
            let body = JSON.stringify({data:job,auth:loginState});
            fetch(Commons.getURL("jobs")+"/update.php",{
                method:"POST",body:body,headers:{"Content-type":"application/json"}
            })
            .then(res=>res.json())
            .then(result=>{
                setIsLoading(false);
                resolve(result);
            })
            .catch(e=>{
                setIsLoading(false);
                console.error("JobEdit.update(): ",e);
                reject("Oops! Something went wrong")
            })
        })
        
        
    }
    const handleFocus=(event)=>{
        event.preventDefault();
        event.target.type = "date"
    }
    return(<form encType="multipart/form-data" onSubmit={handleSubmit} className="border border-secondary bg-light p-3 my-1 col-md-8 col-lg-8 col-sm-10 offset-md-2 offset-lg-2 offset-sm-1 row">
    <div className="text-medium py-2">Edit Job Details</div>
    {feedback.length > 0 ? <div id="feedback" className={"py-2 my-2 "+(hasError ? "alert-danger" : "alert-success")}>{feedback}</div>:null}
    
    <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
        <label htmlFor="position">Job Position/Title *</label>
        <input type="text" className="form-control border text-left" id="position" name="position" defaultValue={props.job.title}  onChange={handleChange}/>
       
    </div>
    <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
        <label htmlFor="experience">Experience (Years)</label>
        <select className="form-control border text-left" id="experience" name="experience" defaultValue={props.job.experience} onChange={handleChange}>
        {Commons.generateIntegerArray(20,0).map(y=>{
                return (<option key={y}>{y}</option>)
            })}
        </select>
           
    </div>
    <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
        <label htmlFor="location">Location</label>
        <select className="form-control border text-left" id="location" name="location" defaultValue={props.job.location} onChange={handleChange}>
        <option value="remote">Remote</option>
        {regionData[0].regions.map(city=>{
                return(<option key={city.key} value={city.key}>{city.name}</option>)
            })}
        </select>
        
    </div>
   
    <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
        <label htmlFor="employment">Type of Employment</label>
        <select required={true} className="form-control" id="employment" name="employment" defaultValue={props.job.employment} onChange={handleChange}>
        <option>Full Time</option> 
        <option>Part Time</option>
        <option>Contract</option>
        <option>Consultant</option>
        </select>
    </div>
    <div className="my-2 d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
        <label htmlFor="institution">Institution</label>
        <input type="text" className="form-control text-left" id="institution" name="institution" defaultValue={props.job.institution} onChange={handleChange}/>
    </div>
    <div className="my-2 d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
        <label htmlFor="salary">Salary</label>
        <input type="text" className="form-control text-left" id="salary" name="salary" defaultValue={props.job.salary} onChange={handleChange}/>
    </div>
    <div className="my-2 d-flex flex-column align-items-start justify-content-start col-md-12 col-lg-12">
        <label htmlFor="description">Description</label>
        <textarea className="form-control border text-left" id="description" name="description" style={{height:"auto"}} defaultValue={props.job.description} onChange={handleChange}></textarea>
    </div>
   
    <div className="my-2 d-flex flex-column align-items-start justify-content-start col-md-12 col-lg-12">
        <label htmlFor="qualification">Qualifications</label>
        <textarea className="form-control border text-left"  id="qualification" name="qualification" style={{height:"auto  "}} defaultValue={props.job.qualification} onChange={handleChange}></textarea>
    </div>
   
   
    <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-12 col-md-12">
        <label htmlFor="duties">Duties and Responsibilities</label>
        <textarea className="form-control border text-left"  id="duties" name="duties"  style={{height:"auto "}} defaultValue={props.job.duties} onChange={handleChange}></textarea>
    </div>
    <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
        <label htmlFor="address">Application Address</label>
        <textarea className="form-control border text-left"  id="address" name="address" defaultValue={props.job.address} onChange={handleChange}></textarea>
    </div>
    <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
        <label htmlFor="deadline">Application Deadline</label>
        <input className={"form-control border text-left"} type="text" id="deadline" name="deadline" defaultValue={deadline} onFocus={handleFocus} onChange={handleChange}/>
    </div>
    <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
        <label htmlFor="deadline">Attachment</label>
        <div className={"form-control border text-left"}>
            {props.job.attachment.length>0 ? <a href={Commons.getURL("jobs")+"/uploads/"+props.job.attachment} target="_blank">View Document</a>:<span>No attachment</span>}
        </div>
    </div>
    <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
        <label htmlFor="deadline">Upload New Attachment (PDF only)</label>
        <input className={"form-control"} type="file" id="attachment" name="attachment" onChange={handleChange} accept="application/pdf"/>
    </div>
    <div className="my-2 form-group d-flex align-items-center justify-content-between flex-row">
        {isLoading ? <Spinner isShowing={true} size="spinner"/>:<input className="col-md-3 col-lg-2 col-sm-6 btn bg-primary text-white" type="submit" value="UPDATE"/>}
        <input onClick={()=>handleCancelForm("jobs")} className="text-primary col-md-3 col-lg-2 col-sm-6 btn" type="button" value="CANCEL"/>
    </div>
</form>
)
}
export default JobEdit;