import React from "react";
import Commons from "../utils/commons";

class UserItem extends React.Component{
    constructor(props){
        super(props);
        this.storage = window.localStorage;
        this.storedData = JSON.parse(this.storage.getItem("data"));
        this.handleClick = this.handleClick.bind(this);
        this.userLabels = Commons.getCandidatesListLabels().filter(f=>f.key=="email" || f.key=="phone"||f.key=="fname"||f.key=="lname");
        this.state={user:props.user}
    }

    handleClick=()=>{
        this.props.onClick(this.state.user);
    }
    componentDidMount(){
       console.log("ser; ",this.props.user);
    }
    render(){
        return(
            this.state.user ? (<div onClick={this.handleClick} className={"mx-auto row col-md-12 col-lg-12 d-flex flex-row justify-content-start align-items-center text-left list-item "+((this.props.position%2==0) ? "list-item-shade":"bg-light")}>
            {
                this.userLabels.map((d,i)=>{
                    return(<div key={i} className={"col-lg-3 col-md-3 text-left" }>{this.state.user[d.key]}</div>)
                
                })  
            }</div>):(
                    <div className="text-center col-lg-12 col-md-12">No candidates</div>
                )
                
            
        )
    }
}
export default UserItem;