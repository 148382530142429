import userEvent from "@testing-library/user-event";
import React from "react";
import Commons from "../utils/commons";

class ApplicationItem extends React.Component{
    constructor(props){
        super(props);
        this.storage = window.localStorage;
        this.storedData = JSON.parse(this.storage.getItem("data"));
        this.loginState = JSON.parse(window.sessionStorage.getItem("loginState"));
        this.handleClick = this.handleClick.bind(this);
        this.applicationLabels = Commons.getApplicationListLabels().filter(f=>f.key != "id" && f.key != "document_link" && f.key != "date_updated");
        this.state={application:props.application}
        this.width = "col-lg-2 col-md-2";
        if(this.applicationLabels.length > 6) this.width = "col-lg-1 col-md-1";
        if(this.applicationLabels.length == 4) this.width = "col-lg-3 col-md-3";
        if(this.applicationLabels.length == 3) this.width = "col-lg-4 col-md-4";
        this.align = "text-left";
    }

    handleClick=()=>{
        this.props.onClick(this.state.application);
    }
    componentDidMount(){
       
    }
    render(){
        return(
            this.state.application ? (<div onClick={this.handleClick} className={"mx-auto row col-md-12 col-lg-12 d-flex flex-row justify-content-start align-items-center text-left list-item "+((this.props.position%2==0) ? "list-item-shade":"bg-light")}>
            {
                this.applicationLabels.map((d,i)=>{
                    let val = this.state.application[d.key];
                    if(d.key == "job_id") {
                        let id= val;
                        let job = this.storedData.jobs.find(j=>j.id == id);
                        val = job.title;
                    }
                    if(d.key == "status") val = Commons.getStatusText(val);
                    if(d.key == "user_id") {
                        let id= val;
                        let u = {};
                        if(this.loginState.level == Commons.LEVEL_ADMIN){
                            u = this.storedData.users.find(u=>u.user_id == id);
                        }
                        else u = this.loginState;
                        val = u.fname+" "+u.lname;
                    }
                   
                    if(d.key == "deadline" || d.key == "date_created") {
                        let x = new Date(parseInt(val));
                        val = Commons.getDays()[x.getDay()]+ " "+Commons.generateMonths()[x.getMonth()]+" "+x.getDate()+", "+x.getFullYear();
                    }
                    return(<div key={i} className={this.width+ " "+this.align }>{val}</div>)
                
                })  
            }</div>):(
                    <div className="text-center col-lg-12 col-md-12">No information</div>
                )
                
            
        )
    }
}
export default ApplicationItem;